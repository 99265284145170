import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import ProdBaseRadio from "@/components/Inputs/ProdBaseRadio";
import ProdPaymentPlanRadio from "@/components/Inputs/ProdPaymentPlanRadio.vue";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from '@/components/BaseHeader';
import { Input, Tooltip, Popover } from 'element-ui';
import VueSimpleSpinner from 'vue-simple-spinner';
import VueCountdownTimer from 'vuejs-countdown-timer';
import {
  VueContentLoading,
  VclCode,
  VclList,
  VclTwitch,
  VclFacebook,
  VclInstagram,
  VclBulletList,
  VclTable,
} from 'vue-content-loading';
import {Tabs, Tab} from '@/components/Tabs/vue-tabs-component';
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import {BSidebar} from 'bootstrap-vue';


/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
//import Argon Design System
import DesignBadge from "../components/ArgonDesignSystem/DesignBadge";
import DesignBaseAlert from "../components/ArgonDesignSystem/DesignBaseAlert";
import DesignBaseButton from "../components/ArgonDesignSystem/DesignBaseButton";
import DesignBaseCheckbox from "../components/ArgonDesignSystem/DesignBaseCheckbox";
import DesignBaseInput from "../components/ArgonDesignSystem/DesignBaseInput";
import DesignBasePagination from "../components/ArgonDesignSystem/DesignBasePagination";
import DesignBaseProgress from "../components/ArgonDesignSystem/DesignBaseProgress";
import DesignBaseRadio from "../components/ArgonDesignSystem/DesignBaseRadio";
import DesignBaseSlider from "../components/ArgonDesignSystem/DesignBaseSlider";
import DesignBaseSwitch from "../components/ArgonDesignSystem/DesignBaseSwitch";
import DesignCard from "../components/ArgonDesignSystem/DesignCard";
import DesignIcon from "../components/ArgonDesignSystem/DesignIcon";

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(ProdBaseRadio.name, ProdBaseRadio);
    Vue.component(ProdPaymentPlanRadio.name, ProdPaymentPlanRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Input.name, Input);
    Vue.component(VueSimpleSpinner.name, VueSimpleSpinner);
    Vue.component(VueContentLoading.name, VueContentLoading);
    Vue.component('vcl-code', VclCode);
    Vue.component('vcl-list', VclList)
    Vue.component('vcl-twitch', VclTwitch)
    Vue.component('vcl-facebook', VclFacebook)
    Vue.component('vcl-instagram', VclInstagram)
    Vue.component('vcl-bullet-list', VclBulletList)
    Vue.component('vcl-table', VclTable)
    Vue.component('v-tabs', Tabs);
    Vue.component('v-tab', Tab);
    Vue.component('multiselect', Multiselect);
    Vue.component('loading', Loading);
    Vue.component('b-sidebar', BSidebar);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(VueCountdownTimer);
	//import Argon Design System
    Vue.component(DesignBadge.name, DesignBadge);
    Vue.component(DesignBaseAlert.name, DesignBaseAlert);
    Vue.component(DesignBaseButton.name, DesignBaseButton);
    Vue.component(DesignBaseInput.name, DesignBaseInput);
    Vue.component(DesignBaseCheckbox.name, DesignBaseCheckbox);
    Vue.component(DesignBasePagination.name, DesignBasePagination);
    Vue.component(DesignBaseProgress.name, DesignBaseProgress);
    Vue.component(DesignBaseRadio.name, DesignBaseRadio);
    Vue.component(DesignBaseSlider.name, DesignBaseSlider);
    Vue.component(DesignBaseSwitch.name, DesignBaseSwitch);
    Vue.component(DesignCard.name, DesignCard);
    Vue.component(DesignIcon.name, DesignIcon);
  }
};

export default GlobalComponents;
