// src/plugins/vuetify.js

import Vue from 'vue'
//instead of import vuetify this way we will import from lib and try to load it only where it is used
//import Vuetify from 'vuetify'
import Vuetify from 'vuetify/lib'
//import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)