import affiliate from '@/api/affiliate';
import session from '@/api/session';
import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_REFERRALS,
        SAVE_REFERRAL,
        PUSH_TO_REFERRALS,
       } from './types'

const state = {
  error: {},
  loading: false,
  referrals: [],
  referral: {},
}
const getters = {
  error (state) {
    return state.error;
  },
  referrals (state) {
    return state.referrals
  },
  referral (state) {
    return state.referral
  }
}
const actions = {
  get_referrals({ commit }, { params }) {
    return affiliate
      .get_referrals(params)
      .then(({data}) => {
        commit(SAVE_REFERRALS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_referral({ commit }, { referral_id }) {
    return affiliate
      .get_referral(referral_id)
      .then(({data}) => {
        commit(SAVE_REFERRAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_referral({ commit }, { referral_id }) {
    return affiliate
      .delete_referral(referral_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_referral({ commit }, payload) {
    return affiliate
      .post_referral(payload)
      .then(({data}) => {
        commit(SAVE_REFERRAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_referral({ commit }, { referral_id, payload }) {
    return affiliate
      .patch_referral(referral_id, payload)
      .then(({data}) => {
        commit(SAVE_REFERRAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_process_referral({ commit }, payload) {
    return affiliate
      .post_process_referral(payload)
      .then(({data}) => {
        commit(SAVE_REFERRAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_process_multiple_referral({ commit }, payload) {
    return affiliate
      .post_process_multiple_referral(payload)
      .then(({data}) => {
        commit(SAVE_REFERRALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_REFERRALS] (state, referrals) {
    state.referrals = referrals
    state.error = {}
  },
  [SAVE_REFERRAL] (state, referral) {
    state.referral = referral
    state.error = {}
  },
  [PUSH_TO_REFERRALS] (state, referral) {
    state.referrals.push(referral)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}