import session from './session';

export default {
  get_mailboxes(params) {
    return session.get('/api/inbox/mailboxes/', { params: params });
  },
  get_mailbox(mailbox_id) {
    return session.get(`/api/inbox/mailboxes/${mailbox_id}/`);
  },
  post_mailbox(data) {
    return session.post('/api/inbox/mailboxes/', data);
  },
  patch_mailbox(mailbox_id, data) {
    return session.patch(`/api/inbox/mailboxes/${mailbox_id}/`, data);
  },
  delete_mailbox(mailbox_id) {
    return session.delete(`/api/inbox/mailboxes/${mailbox_id}/`);
  },
  get_messages(params) {
    return session.get('/api/inbox/messages/', { params: params });
  },
  get_message(message_id) {
    return session.get(`/api/inbox/messages/${message_id}/`);
  },
  patch_message(message_id, data) {
    return session.patch(`/api/inbox/messages/${message_id}/`, data);
  },
  post_test_imap_connection(email, password, server, port) {
    return session.post('/api/inbox/test_imap_connection/', { email, password, server, port })
  }
};