import dashboard from '@/api/dashboard';
import session from '@/api/session';
import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_NOTIFICATIONS,
        SAVE_NOTIFICATION,
       } from './types'

const state = {
  error: {},
  loading: false,
  notifications: [],
  notification: {},
}
const getters = {
  error (state) {
    return state.error;
  },
  notifications (state) {
    return state.notifications
  },
  notification (state) {
    return state.notification
  }
}
const actions = {
  get_all_notifications_for_user_portal({ commit }, { params }) {
    return dashboard
      .get_all_notifications_for_user_portal(params)
      .then(({data}) => {
        commit(SAVE_NOTIFICATIONS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_notifications({ commit }, { params }) {
    return dashboard
      .get_notifications(params)
      .then(({data}) => {
        commit(SAVE_NOTIFICATIONS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_notification({ commit }, { notification_id }) {
    return dashboard
      .get_notification(notification_id)
      .then(({data}) => {
        commit(SAVE_NOTIFICATION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_notification({ commit }, { notification_id }) {
    return dashboard
      .delete_notification(notification_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_notification({ commit }, payload) {
    return dashboard
      .post_notification(payload)
      .then(({data}) => {
        commit(SAVE_NOTIFICATION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_notification({ commit }, { notification_id, payload }) {
    return dashboard
      .patch_notification(notification_id, payload)
      .then(({data}) => {
        commit(SAVE_NOTIFICATION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_NOTIFICATIONS] (state, notifications) {
    state.notifications = notifications
    state.error = {}
  },
  [SAVE_NOTIFICATION] (state, notification) {
    state.notification = notification
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}