import session from './session';

export default {
  get_signup_survey(signup_survey_id) {
    return session.get(`/api/survey/signupsurveys/${signup_survey_id}/`);
  },
  get_signup_surveys(params) {
    return session.get('/api/survey/signupsurveys/', { params: params });
  },
  post_signup_survey(data) {
    return session.post('/api/survey/signupsurveys/', data);
  },
  patch_signup_survey(signup_survey_id, data) {
    return session.patch(`/api/survey/signupsurveys/${signup_survey_id}/`, data);
  }
};