import campaign from '@/api/campaign';

import { LOADING_START,
     LOADING_END,
     SAVE_ERROR,
     SAVE_RETURN_PRODUCT_PERMISSIONS,
     SAVE_IS_APPROVED_QUALIFIED_INVESTOR,
		 SAVE_LISTS,
		 SAVE_LIST,
		 SAVE_TOTAL_LISTS,
     SAVE_PORTAL_LIST,
     SAVE_ONE_OFF_EMAIL_QUEUE,
     SAVE_ONE_OFF_EMAIL_QUEUES,
     SAVE_TOTAL_ONE_OFF_EMAIL_QUEUES,
     SAVE_ONE_OFF_EMAIL_QUEUES_SENT,
     SAVE_TOTAL_PORTAL_EMAIL_CREDITS,
       } from './types';

const state = {
  error: {},
  loading: false,
  return_product_permissions: [],
  is_approved_qualified_investor: false,
  lists: [],
  list: {},
  total_lists: {},
  portal_list: {},
  one_off_email_queue: {},
  one_off_email_queues: [],
  total_one_off_email_queues: null,
  one_off_email_queues_sent: null,
  total_portal_email_credits: null,
};
const getters = {
  error (state) {
    return state.error;
  },
  return_product_permissions (state) {
    return state.return_product_permissions
  },
  is_approved_qualified_investor (state) {
    return state.is_approved_qualified_investor
  },
  lists (state) {
    return state.lists
  },
  list (state) {
    return state.list
  },
  total_lists (state) {
    return state.total_lists
  },
  portal_list (state) {
    return state.portal_list;
  },
  one_off_email_queue (state) {
    return state.one_off_email_queue;
  },
  one_off_email_queues (state) {
    return state.one_off_email_queues;
  },
  total_one_off_email_queues (state) {
    return state.total_one_off_email_queues;
  },
  one_off_email_queues_sent (state) {
    return state.one_off_email_queues_sent;
  },
  total_portal_email_credits (state) {
    return state.total_portal_email_credits;
  }
};
const actions = {
  get_return_product_permissions({ commit }, portal_id) {
    return campaign
      .return_product_permissions(portal_id)
      .then(({data}) => {
        commit(SAVE_RETURN_PRODUCT_PERMISSIONS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_is_approved_qualified_investor({ commit }, portal_id) {
    return campaign
      .is_approved_qualified_investor(portal_id)
      .then(({data}) => {
        commit(SAVE_IS_APPROVED_QUALIFIED_INVESTOR, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_lists({ commit }, { params }) {
    return campaign
      .get_lists(params)
      .then(({data}) => {
        commit(SAVE_LISTS, data.results);
		    commit(SAVE_TOTAL_LISTS, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_list({ commit }, { list_id }) {
    return campaign
      .get_list(list_id)
      .then(({data}) => {
        commit(SAVE_LIST, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_list({ commit }, payload) {
    return campaign
      .post_list(payload)
      .then(({data}) => {
        commit(SAVE_LIST, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_list({ commit }, { list_id, payload }) {
    return campaign
      .patch_list(list_id, payload)
      .then(({data}) => {
        commit(SAVE_LIST, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_list({ commit }, { list_id }) {
    return campaign
      .delete_list(list_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_portal_list({ commit }, payload) {
    return campaign
      .post_portal_list(payload)
      .then(({data}) => {
        commit(SAVE_PORTAL_LIST, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_add_selected_to_list({ commit }, payload) {
    return campaign
      .post_add_selected_to_list(payload)
      .then(({data}) => {
        //commit(SAVE_PORTAL_LIST, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_one_off_email_queues({ commit }, { params }) {
    return campaign
      .get_one_off_email_queues(params)
      .then(({data}) => {
        commit(SAVE_ONE_OFF_EMAIL_QUEUES, data.results);
		    commit(SAVE_TOTAL_ONE_OFF_EMAIL_QUEUES, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_one_off_email_queue({ commit }, { campaign_id }) {
    return campaign
      .get_one_off_email_queue(campaign_id)
      .then(({data}) => {
        commit(SAVE_ONE_OFF_EMAIL_QUEUE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_one_off_email_queue({ commit }, payload) {
    return campaign
      .post_one_off_email_queue(payload)
      .then(({data}) => {
        commit(SAVE_ONE_OFF_EMAIL_QUEUE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_one_off_email_queues_sent({ commit }, { days, portal_id }) {
    return campaign
      .get_one_off_email_queues_sent(days, portal_id)
      .then(({data}) => {
        commit(SAVE_ONE_OFF_EMAIL_QUEUES_SENT, data.number_sent);
        commit(SAVE_TOTAL_PORTAL_EMAIL_CREDITS, data.total_credits);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
};
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_RETURN_PRODUCT_PERMISSIONS] (state, return_product_permissions) {
    state.return_product_permissions = return_product_permissions
    state.error = {}
  },
  [SAVE_IS_APPROVED_QUALIFIED_INVESTOR] (state, is_approved_qualified_investor) {
    state.is_approved_qualified_investor = is_approved_qualified_investor
    state.error = {}
  },
  [SAVE_LISTS] (state, lists) {
    state.lists = lists
    state.error = {}
  },
  [SAVE_LIST] (state, list) {
    state.list = list
    state.error = {}
  },
  [SAVE_TOTAL_LISTS] (state, count) {
    state.total_lists = count
    state.error = {}
  },
  [SAVE_PORTAL_LIST] (state, portal_list) {
    state.portal_list = portal_list
    state.error = {}
  },
  [SAVE_ONE_OFF_EMAIL_QUEUE] (state, one_off_email_queue) {
    state.one_off_email_queue = one_off_email_queue
    state.error = {}
  },
  [SAVE_ONE_OFF_EMAIL_QUEUES] (state, one_off_email_queues) {
    state.one_off_email_queues = one_off_email_queues
    state.error = {}
  },
  [SAVE_TOTAL_ONE_OFF_EMAIL_QUEUES] (state, total_one_off_email_queues) {
    state.total_one_off_email_queues = total_one_off_email_queues
    state.error = {}
  },
  [SAVE_ONE_OFF_EMAIL_QUEUES_SENT] (state, one_off_email_queues_sent) {
    state.one_off_email_queues_sent = one_off_email_queues_sent
    state.error = {}
  },
  [SAVE_TOTAL_PORTAL_EMAIL_CREDITS] (state, total_portal_email_credits) {
    state.total_portal_email_credits = total_portal_email_credits
    state.error = {}
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};