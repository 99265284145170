// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules

export const SAVE_SIGNUP_SURVEY = "SAVE_SIGNUP_SURVEY"
export const SAVE_SIGNUP_SURVEYS = "SAVE_SIGNUP_SURVEYS"

export const SAVE_INVESTMENT_FOCUS_SURVEY = "SAVE_INVESTMENT_FOCUS_SURVEY"
export const SAVE_INVESTMENT_FOCUS_SURVEYS = "SAVE_INVESTMENT_FOCUS_SURVEYS"

