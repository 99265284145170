import session from './session';

export default {
  get_linkedin_user_setting(linkedin_user_settings_id) {
    return session.get(`/api/linkedinusersettings/${linkedin_user_settings_id}/`);
  },
  get_linkedin_user_settings(params) {
    return session.get('/api/linkedinusersettings/', { params: params });
  },
  post_linkedin_user_setting(data) {
    return session.post('/api/linkedinusersettings/', data);
  },
  patch_linkedin_user_setting(linkedin_user_settings_id, data) {
    return session.patch(`/api/linkedinusersettings/${linkedin_user_settings_id}/`, data);
  },
  post_start_linkedin_verification(data) {
    return session.post('/api/start_linkedin_verification/', data);
  },
  get_hubspot_user_setting(hubspot_user_settings_id) {
    return session.get(`/api/hubspotusersettings/${hubspot_user_settings_id}/`);
  },
  get_hubspot_user_settings(params) {
    return session.get('/api/hubspotusersettings/', { params: params });
  },
  post_hubspot_user_setting(data) {
    return session.post('/api/hubspotusersettings/', data);
  },
  patch_hubspot_user_setting(hubspot_user_settings_id, data) {
    return session.patch(`/api/hubspotusersettings/${hubspot_user_settings_id}/`, data);
  },
  get_all_user_portal_settings(params) {
    return session.get('/api/get_all_user_portal_settings/', { params: params });
  },
  get_marketing_questionnaires(params) {
    return session.get('/api/marketingquestionnaires/', { params: params });
  },
  post_marketing_questionnaire(data) {
    return session.post('/api/marketingquestionnaires/', data);
  },
  patch_marketing_questionnaire(marketing_questionnaire_id, data) {
    return session.patch(`/api/marketingquestionnaires/${marketing_questionnaire_id}/`, data);
  },
  get_email_settings(params) {
    return session.get('/api/emailsettings/', { params: params });
  },
  post_email_setting(data) {
    return session.post('/api/emailsettings/', data);
  },
  patch_email_setting(email_settings_id, data) {
    return session.patch(`/api/emailsettings/${email_settings_id}/`, data);
  },
  get_calendar_settings(params) {
    return session.get('/api/calendarsettings/', { params: params });
  },
  post_calendar_setting(data) {
    return session.post('/api/calendarsettings/', data);
  },
  patch_calendar_setting(calendar_settings_id, data) {
    return session.patch(`/api/calendarsettings/${calendar_settings_id}/`, data);
  },
  get_copy_generator(params) {
    return session.get('/api/copy-generator/', { params: params });
  },
};