import session from './session';

export default {
  return_product_permissions(portal_id) {
    return session.get('/api/campaign/return_product_permissions/',{ params: {portal_id} });
  },
  is_approved_qualified_investor(portal_id) {
    return session.get('/api/campaign/is_approved_qualified_investor/',{ params: {portal_id} });
  },
  get_lists(params) {
    return session.get('/api/campaign/lists/', { params: params });
  },
  get_list(list_id) {
    return session.get(`/api/campaign/lists/${list_id}/`);
  },
  post_list(data) {
    return session.post('/api/campaign/lists/', data);
  },
  patch_list(list_id, data) {
    return session.patch(`/api/campaign/lists/${list_id}/`, data);
  },
  delete_list(list_id) {
    return session.delete(`/api/campaign/lists/${list_id}/`);
  },
  post_portal_list(data) {
    return session.post('/api/campaign/portallists/', data);
  },
  post_add_selected_to_list(data) {
    return session.post('/api/campaign/add_selected_to_list/', data);
  },
  get_one_off_email_queues(params) {
    return session.get('/api/campaign/oneoffemailqueues/', { params: params });
  },
  get_one_off_email_queue(one_off_email_queue_id) {
    return session.get(`/api/campaign/oneoffemailqueues/${one_off_email_queue_id}/`);
  },
  post_one_off_email_queue(data) {
    return session.post('/api/campaign/oneoffemailqueues/', data);
  },
  get_one_off_email_queues_sent(days, portal_id) {
    return session.get('/api/campaign/one_off_email_queues_sent/', { params: {days: days, portal_id: portal_id} });
  },
};

