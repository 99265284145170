import outbox from '@/api/outbox';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_OUTBOXES,
        SAVE_OUTBOX,
        SAVE_EMAIL_TEMPLATES,
        SAVE_EMAIL_TEMPLATE,
        SAVE_TOTAL_EMAIL_TEMPLATES,
        SAVE_TEST_SMTP_CONNECTION,
        SAVE_LATEST_EMAILS_SENT,
        SAVE_TOTAL_EMAILS_SENT,
       } from './types'

const state = {
  error: {},
  loading: false,
  outboxes: [],
  outbox: {},
  email_templates: {},
  email_template: {},
  total_email_templates: {},
  test_smtp_connection: {},
  latest_emails_sent: [],
  total_emails_sent: null,
}
const getters = {
  error (state) {
    return state.error;
  },
  outboxes (state) {
    return state.outboxes
  },
  outbox (state) {
    return state.outbox
  },
  email_templates (state) {
    return state.email_templates
  },
  email_template (state) {
    return state.email_template
  },
  total_email_templates (state) {
    return state.total_email_templates
  },
  test_smtp_connection (state) {
    return state.test_smtp_connection
  },
  latest_emails_sent (state) {
    return state.latest_emails_sent
  },
  total_emails_sent (state) {
    return state.total_emails_sent
  },
}
const actions = {
  get_outboxes({ commit }, { params }) {
    return outbox
      .get_outboxes(params)
      .then(({data}) => {
        commit(SAVE_OUTBOXES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_outbox({ commit }, { outbox_id }) {
    return outbox
      .get_outbox(outbox_id)
      .then(({data}) => {
        commit(SAVE_OUTBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_outbox({ commit }, payload) {
    return outbox
      .post_outbox(payload)
      .then(({data}) => {
        commit(SAVE_OUTBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_outbox({ commit }, { outbox_id, payload }) {
    return outbox
      .patch_outbox(outbox_id, payload)
      .then(({data}) => {
        commit(SAVE_OUTBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_outbox({ commit }, { outbox_id }) {
    return outbox
      .delete_outbox(outbox_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_templates({ commit }, { params }) {
    return outbox
      .get_email_templates(params)
      .then(({data}) => {
        commit(SAVE_EMAIL_TEMPLATES, data.results);
		    commit(SAVE_TOTAL_EMAIL_TEMPLATES, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_template({ commit }, { email_template_id }) {
    return outbox
      .get_email_template(email_template_id)
      .then(({data}) => {
        commit(SAVE_EMAIL_TEMPLATE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_email_template({ commit }, { email_template_id }) {
    return outbox
      .delete_email_template(email_template_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_email_template({ commit }, payload) {
    return outbox
      .post_email_template(payload)
      .then(({data}) => {
        commit(SAVE_EMAIL_TEMPLATE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_email_template({ commit }, { email_template_id, payload }) {
    return outbox
      .patch_email_template(email_template_id, payload)
      .then(({data}) => {
        commit(SAVE_EMAIL_TEMPLATE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_test_smtp_connection({ commit }, { email, password, server, port }) {
    return outbox
      .post_test_smtp_connection(email, password, server, port)
      .then(({data}) => {
        commit(SAVE_TEST_SMTP_CONNECTION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_latest_emails_sent({ commit }, {params}) {
    return outbox
      .get_latest_emails_sent(params)
      .then(({data}) => {
        commit(SAVE_LATEST_EMAILS_SENT, data.results);
        commit(SAVE_TOTAL_EMAILS_SENT, data.total_emails_sent);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_OUTBOXES] (state, outboxes) {
    state.outboxes = outboxes
    state.error = {}
  },
  [SAVE_OUTBOX] (state, outbox) {
    state.outbox = outbox
    state.error = {}
  },
  [SAVE_EMAIL_TEMPLATES] (state, email_templates) {
    state.email_templates = email_templates
    state.error = {}
  },
  [SAVE_EMAIL_TEMPLATE] (state, email_template) {
    state.email_template = email_template
    state.error = {}
  },
  [SAVE_TOTAL_EMAIL_TEMPLATES] (state, count) {
    state.total_email_templates = count
    state.error = {}
  },
  [SAVE_TEST_SMTP_CONNECTION] (state, test_smtp_connection) {
    state.test_smtp_connection = test_smtp_connection
    state.error = {}
  },
  [SAVE_LATEST_EMAILS_SENT] (state, latest_emails_sent) {
    state.latest_emails_sent = latest_emails_sent
    state.error = {}
  },
  [SAVE_TOTAL_EMAILS_SENT] (state, total_emails_sent) {
    state.total_emails_sent = total_emails_sent
    state.error = {}
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}