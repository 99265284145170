import session from './session';

export default {
  get_referrals(params) {
    return session.get('/api/affiliate/referrals/', { params: params });
  },
  get_referral(referral_id) {
    return session.get(`/api/affiliate/referrals/${referral_id}/`);
  },
  delete_referral(referral_id) {
    return session.delete(`/api/affiliate/referrals/${referral_id}/`);
  },
  post_referral(data) {
    return session.post('/api/affiliate/referrals/', data);
  },
  patch_referral(referral_id, data) {
    return session.patch(`/api/affiliate/referrals/${referral_id}/`, data);
  },
  post_process_referral(data) {
    return session.post('/api/affiliate/process_referral/', data);
  },
  post_process_multiple_referral(data) {
    return session.post('/api/affiliate/process_multiple_referral/', data);
  },
};