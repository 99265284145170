// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules

export const SAVE_RETURN_PRODUCT_PERMISSIONS = "SAVE_RETURN_PRODUCT_PERMISSIONS"
export const SAVE_IS_APPROVED_QUALIFIED_INVESTOR = "SAVE_IS_APPROVED_QUALIFIED_INVESTOR"

export const SAVE_LISTS = "SAVE_LISTS"
export const SAVE_LIST = "SAVE_LIST"
export const SAVE_TOTAL_LISTS = "SAVE_TOTAL_LISTS"

export const SAVE_PORTAL_LIST = "SAVE_PORTAL_LIST"

export const SAVE_ONE_OFF_EMAIL_QUEUE = "SAVE_ONE_OFF_EMAIL_QUEUE"
export const SAVE_ONE_OFF_EMAIL_QUEUES = "SAVE_ONE_OFF_EMAIL_QUEUES"
export const SAVE_TOTAL_ONE_OFF_EMAIL_QUEUES = "SAVE_TOTAL_ONE_OFF_EMAIL_QUEUES"
export const SAVE_ONE_OFF_EMAIL_QUEUES_SENT = "SAVE_ONE_OFF_EMAIL_QUEUES_SENT"
export const SAVE_TOTAL_PORTAL_EMAIL_CREDITS = "SAVE_TOTAL_PORTAL_EMAIL_CREDITS"


