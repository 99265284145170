import accounts from '@/api/accounts';
import session from '@/api/session';
import store from '@/store';


import { SAVE_SESSION,
         SAVE_COOKIE,
         SAVE_PERM,
         SAVE_USER_PORTAL_SETTINGS_ID,
         SAVE_USER_ID,
         SAVE_USER,
         SAVE_PORTAL_ID,
         SAVE_HAS_PERM_FOR_PORTAL,
         SAVE_NO_PORTAL_ID_SET,
         SAVE_ONBOARDING_STATUS,
  		   LOADING_START,
	    	 LOADING_END,
         SAVE_ERROR,
         SAVE_AGENCY_PORTALS,
         SAVE_MY_PORTALS,
         SAVE_SOCIAL_ACCOUNT,
         SAVE_SOCIAL_ACCOUNTS,
         SAVE_DISCONNECT_SOCIAL_ACCOUNT,
         SAVE_LINKEDIN_OAUTH_CREDENTIALS,
         SAVE_MICROSOFT_OAUTH_CREDENTIALS,
         SAVE_LINKEDIN_ACCESS_TOKEN,
         SAVE_LINKEDIN_MEMBER,
         SAVE_LINKEDIN_MEMBER_PROFILE,
         SAVE_LINKEDIN_MEMBER_PROFILE_PICTURE,
         SAVE_LINKEDIN_MEMBER_EMAIL,
         SAVE_HUBSPOT_OAUTH_CREDENTIALS,
         SAVE_HUBSPOT_ACCESS_TOKEN,
         SAVE_HUBSPOT_USERS,
         SAVE_EMAIL_AVAILABLE,
         SAVE_USER_REGISTERED_BEFORE,
         SAVE_LOGOUT,
         ACTION_BEGIN,
         ACTION_SUCCESS,
         ACTION_FAILURE,
         SAVE_GOOGLE_OAUTH_CREDENTIALS,
         RESET_STATE_ON_LOGOUT,
         SAVE_CREATED_PORTAL,
         SAVE_ADMIN_VIEW_AS_USER_PORTAL_SETTINGS,
         SAVE_HAS_OBJECT_PERMISSION,
         SAVE_USER_PROFILE_SLUG,
         SAVE_FLATFILE_KEY,
       } from './types'

const state = {
  //previousRoute: null,
  session: null,
  cookie: null,
  permissions: {},
  user_portal_settings_id: null,
  user_id: null,
  user: {
    first_name: '',
    last_name: '',
  },
  portal_id: 'not_set',
  has_perm_for_portal: false,
  no_portal_id_set: true,
  onboarding_status: '',
  error: {},
  loading: false,
  agency_portals: {
    buy_license_form: {
      amount: null,
      billing_scheme: null,
      currency: null,
      interval: null,
      interval_count: null,
      product: {
        id: null,
        name: null,
        url: null,
      },
      tiers_mode: null,
      usage_type: null,
    },
    agency_portals: []
  },
  my_portals: {
    buy_license_form: {
      amount: null,
      billing_scheme: null,
      currency: null,
      interval: null,
      interval_count: null,
      product: {
        id: null,
        name: null,
        url: null,
      },
      tiers_mode: null,
      usage_type: null,
    },
    not_customer: true,
    portals: [],
  },
  social_account: {},
  social_accounts: [],
  disconnect_social_account: {},
  linkedin_oauth_credentials: {
    client_id: '',
    client_secret: '',
  },
  microsoft_oauth_credentials: {
    client_id: '',
    client_secret: '',
  },
  linkedin_access_token: '',
  linkedin_member: {
    email: '',
    first_name: '',
    last_name: '',
    profile_picture: '',
  },
  linkedin_member_profile: null,
  linkedin_member_profile_picture: null,
  linkedin_member_email: null,
  hubspot_oauth_credentials: {
    client_id: '',
    client_secret: '',
  },
  hubspot_access_token: '',
  hubspot_users: [],
  email_available: null,
  user_registered_before: null,
  logout: false,
  google_oauth_credentials: {
    client_id: '',
    client_secret: '',
  },
  created_portal: null,
  admin_view_as_user_portal_settings: null,
  has_object_permission: null,
  user_profile_slug: '',
  flatfile_key: '',
}
const getters = {
  current_session (state) {
    return state.session
  },
  current_cookie (state) {
    return state.cookie;
  },
  current_permissions (state) {
    return state.permissions;
  },
  user_portal_settings_id (state) {
    return state.user_portal_settings_id
  },
  user_id (state) {
    return state.user_id
  },
  user (state) {
    return state.user
  },
  portal_id (state) {
    return state.portal_id
  },
  has_perm_for_portal (state) {
    return state.has_perm_for_portal
  },
  no_portal_id_set (state) {
    return state.no_portal_id_set
  },
  onboarding_status (state) {
    return state.onboarding_status
  },
  error (state) {
    return state.error;
  },
  agency_portals (state) {
    return state.agency_portals
  },
  my_portals (state) {
    return state.my_portals
  },
  social_account (state) {
    return state.social_account
  },
  social_accounts (state) {
    return state.social_accounts
  },
  disconnect_social_account (state) {
    return state.disconnect_social_account
  },
  linkedin_oauth_credentials (state) {
    return state.linkedin_oauth_credentials
  },
  microsoft_oauth_credentials (state) {
    return state.microsoft_oauth_credentials
  },
  linkedin_access_token (state) {
    return state.linkedin_access_token
  },
  linkedin_member (state) {
    return state.linkedin_member
  },
  linkedin_member_profile (state) {
    return state.linkedin_member_profile
  },
  linkedin_member_profile_picture (state) {
    return state.linkedin_member_profile_picture
  },
  linkedin_member_email (state) {
    return state.linkedin_member_email
  },
  hubspot_oauth_credentials (state) {
    return state.hubspot_oauth_credentials
  },
  hubspot_access_token (state) {
    return state.hubspot_access_token
  },
  hubspot_users (state) {
    return state.hubspot_users
  },
  email_available (state) {
    return state.email_available
  },
  user_registered_before (state) {
    return state.user_registered_before
  },
  logout (state) {
    return state.logout
  },
  google_oauth_credentials (state) {
    return state.google_oauth_credentials
  },
  created_portal (state) {
    return state.created_portal
  },
  admin_view_as_user_portal_settings (state) {
    return state.admin_view_as_user_portal_settings
  },
  has_object_permission (state) {
    return state.has_object_permission
  },
  user_profile_slug (state) {
    return state.user_profile_slug
  },
  flatfile_key (state) {
    return state.flatfile_key
  }
}
const actions = {
	set_session({ commit }, { key, value }) {
    return accounts
      .set_session(key, value)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_session({ commit }, { key }) {
    return accounts
      .get_session(key)
      .then(({data}) => {
        commit(SAVE_SESSION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_session({ commit }, { key }) {
    return accounts
      .delete_session(key)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  set_cookie({ commit }, { name, value, domain }) {
    return accounts
      .set_cookie(name, value, domain)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_cookie({ commit }, { name }) {
    return accounts
      .get_cookie(name)
      .then(({data}) => {
        commit(SAVE_COOKIE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  has_perm({ commit }, { permission, app, model, object_id }) {
    return accounts
      .has_perm(permission, app, model, object_id)
      .then(({data}) => {
        commit(SAVE_PERM, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  has_perm_for_portal_and_set_portal_session({ commit }, { portal_id }) {
    return accounts
      .has_perm_for_portal_and_set_portal_session(portal_id)
      .then(({data}) => {
        commit(SAVE_HAS_PERM_FOR_PORTAL, data.has_perm_for_portal);
        commit(SAVE_NO_PORTAL_ID_SET, data.no_portal_id_set);
        commit(SAVE_PORTAL_ID, data.portal_id);
        commit(SAVE_USER_ID, data.user_id);
        commit(SAVE_USER_PORTAL_SETTINGS_ID, data.user_portal_settings_id);
      })
      .catch(({response}) => {
        //this is used in routes, if api key is incorrect it will trigger loop because this
        //return non 200 response because no access; hence we need person to reauthentificate, so logout
        commit(SAVE_LOGOUT, true)
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_onboarding_status({ commit }) {
    return accounts
      .get_onboarding_status()
      .then(({data}) => {
        commit(SAVE_ONBOARDING_STATUS, data.onboarding_status);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_onboarding_status({ commit }, payload) {
    return accounts
      .post_onboarding_status(payload)
      .then(({data}) => {
        commit(SAVE_ONBOARDING_STATUS, data.onboarding_status);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_current_user_portal_settings({ commit }, { portal_id }) {
    return accounts
      .get_current_user_portal_settings(portal_id)
      .then(({data}) => {
        commit(SAVE_USER_PORTAL_SETTINGS_ID, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_user({ commit }, { user_id }) {
    return accounts
      .get_user(user_id)
      .then(({data}) => {
        commit(SAVE_USER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_user({ commit }, { user_id, payload }) {
    return accounts
      .patch_user(user_id, payload)
      .then(({data}) => {
        commit(SAVE_USER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_normal_user({ commit }, { user_id }) {
    return accounts
      .get_normal_user(user_id)
      .then(({data}) => {
        commit(SAVE_USER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_normal_user({ commit }, { user_id, payload }) {
    return accounts
      .patch_normal_user(user_id, payload)
      .then(({data}) => {
        commit(SAVE_USER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  put_user_profile_picture({ commit }, { profile_picture, filename, content_type }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return accounts
      .put_user_profile_picture(profile_picture, filename, content_type)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  post_connect_facebook({ commit }, payload) {
    return accounts
      .post_connect_facebook(payload)
      .then(({data}) => {
        //store.commit('auth/SET_TOKEN', data.key)
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_connect_microsoft({ commit }, payload) {
    return accounts
      .post_connect_microsoft(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_connect_google({ commit }, payload) {
    commit(ACTION_BEGIN, 'post_connect_google');
    return accounts
      .post_connect_google(payload)
      .then(({data}) => {
      })
      .then(() => commit(ACTION_SUCCESS, 'post_connect_google'))
      .catch(({response}) => {
        //commit(SAVE_ERROR, response.data.errors)
        commit(ACTION_FAILURE, 'post_connect_google')
      })
  },
  post_connect_hubspot({ commit }, payload) {
    return accounts
      .post_connect_hubspot(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_connect_linkedin({ commit }, payload) {
    return accounts
      .post_connect_linkedin(payload)
      .then(({data}) => {
        //store.commit('auth/SET_TOKEN', data.key)
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_oauth_credentials({ commit }) {
    return accounts
      .get_linkedin_oauth_credentials()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_OAUTH_CREDENTIALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_microsoft_oauth_credentials({ commit }) {
    return accounts
      .get_microsoft_oauth_credentials()
      .then(({data}) => {
        commit(SAVE_MICROSOFT_OAUTH_CREDENTIALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_access_token({ commit }) {
    return accounts
      .get_linkedin_access_token()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_ACCESS_TOKEN, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_member({ commit }) {
    return accounts
      .get_linkedin_member()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MEMBER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  /*get_linkedin_member_profile({ commit }, { access_token }) {
    //for next request change headers temporary
    const interceptor=session.interceptors.request.use(config => {
      config.headers.get['Authorization'] = `Bearer ${access_token}`
      return config
    })
    return accounts
      .get_linkedin_member_profile()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MEMBER_PROFILE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      .finally(() => {
        session.interceptors.request.eject(interceptor);
      })
  },
  get_linkedin_member_profile_picture({ commit }, { access_token }) {
    //for next request change headers temporary
    session.interceptors.request.use(config => {
      config.headers.get['Authorization'] = `Bearer ${access_token}`
      return config
    })
    return accounts
      .get_linkedin_member_profile_picture()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MEMBER_PROFILE_PICTURE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      .finally(() => {
        session.interceptors.request.eject(interceptor);
      })
  },
  get_linkedin_member_email({ commit }, { access_token }) {
    //for next request change headers temporary
    session.interceptors.request.use(config => {
      config.headers.get['Authorization'] = `Bearer ${access_token}`
      return config
    })
    return accounts
      .get_linkedin_member_email()
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MEMBER_EMAIL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      .finally(() => {
        session.interceptors.request.eject(interceptor);
      })
  },*/
  get_social_account({ commit }, { social_account_id }) {
    return accounts
      .get_social_account(social_account_id)
      .then(({data}) => {
        commit(SAVE_SOCIAL_ACCOUNT, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_social_accounts({ commit }) {
    return accounts
      .get_social_accounts()
      .then(({data}) => {
        commit(SAVE_SOCIAL_ACCOUNTS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_disconnect_social_account({ commit }, { social_account_id }) {
    return accounts
      .post_disconnect_social_account(social_account_id)
      .then(({data}) => {
        commit(SAVE_DISCONNECT_SOCIAL_ACCOUNT, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_agency_portals({ commit }) {
    return accounts
      .get_agency_portals()
      .then(({data}) => {
        commit(SAVE_AGENCY_PORTALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_my_portals({ commit }) {
    return accounts
      .get_my_portals()
      .then(({data}) => {
        commit(SAVE_MY_PORTALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_hubspot_oauth_credentials({ commit }) {
    return accounts
      .get_hubspot_oauth_credentials()
      .then(({data}) => {
        commit(SAVE_HUBSPOT_OAUTH_CREDENTIALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_hubspot_access_token({ commit }, { portal_id }) {
    return accounts
      .get_hubspot_access_token(portal_id)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_ACCESS_TOKEN, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_hubspot_users({ commit }, { portal_id }) {
    return accounts
      .get_hubspot_users(portal_id)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_USERS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_available({commit}, { email }) {
    return accounts.get_email_available(email)
      .then(({ data }) => commit(SAVE_EMAIL_AVAILABLE, data))
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_user_registered_before({commit}) {
    return accounts.get_user_registered_before()
      .then(({ data }) => commit(SAVE_USER_REGISTERED_BEFORE, data))
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_create_free_account_related_objects({ commit }, { company, traffic_source, funnel }) {
    commit(ACTION_BEGIN, 'post_create_free_account_related_objects');
    return accounts.post_create_free_account_related_objects(company, traffic_source, funnel)
      .then(({ data }) => {
        commit(SAVE_CREATED_PORTAL, data.portal)
      })
      .then(() => commit(ACTION_SUCCESS, 'post_create_free_account_related_objects'))
      .catch(({response}) => {
        //commit(SAVE_ERROR, response.data.errors)
        commit(ACTION_FAILURE, 'post_create_free_account_related_objects')
      })
  },
  get_google_oauth_credentials({ commit }) {
    return accounts
      .get_google_oauth_credentials()
      .then(({data}) => {
        commit(SAVE_GOOGLE_OAUTH_CREDENTIALS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_check_has_object_permission({ commit }, payload) {
    return accounts
      .post_check_has_object_permission(payload)
      .then(({data}) => {
        commit(SAVE_HAS_OBJECT_PERMISSION, data.has_perm);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
	log_error_cloudwatch({ commit }, { value }) {
    return accounts
      .log_error_cloudwatch(value)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
	add_to_mautic_segment({ commit }, { email, segment_id }) {
    return accounts
      .add_to_mautic_segment(email, segment_id)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
	add_mautic_tags({ commit }, { email, list_of_tags }) {
    return accounts
      .add_mautic_tags(email, list_of_tags)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
	set_first_purchase_discount_end_time({ commit }, { email }) {
    return accounts
      .set_first_purchase_discount_end_time(email)
      .then(({data}) => {
        
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_user_profile_slug({ commit }, { user_id }) {
    return accounts
      .get_user_profile_slug(user_id)
      .then(({data}) => {
        commit(SAVE_USER_PROFILE_SLUG, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_flatfile_key({ commit }) {
    return accounts
      .get_flatfile_key()
      .then(({data}) => {
        commit(SAVE_FLATFILE_KEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  /*setPreviousRoute(state, route) {
    state.previousRoute = route;
  },*/
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    //state.error = error
  },
  [SAVE_SESSION] (state, session) {
    state.session = session
    //state.error = {}
  },
  [SAVE_COOKIE] (state, cookie) {
    state.cookie = cookie
    //state.error = {}
  },
  [SAVE_PERM] (state, permissions) {
    state.permissions = permissions
	  //state.error = {}
  },
  [SAVE_USER_PORTAL_SETTINGS_ID] (state, user_portal_settings_id) {
    state.user_portal_settings_id = user_portal_settings_id
	  //state.error = {}
  },
  [SAVE_USER_ID] (state, user_id) {
    state.user_id = user_id
	  //state.error = {}
  },
  [SAVE_USER] (state, user) {
    state.user = user
	  //state.error = {}
  },
  [SAVE_PORTAL_ID] (state, portal_id) {
    state.portal_id = portal_id
    //state.error = {}
  },
  [SAVE_HAS_PERM_FOR_PORTAL] (state, has_perm_for_portal) {
    state.has_perm_for_portal = has_perm_for_portal
    //state.error = {}
  },
  [SAVE_NO_PORTAL_ID_SET] (state, no_portal_id_set) {
    state.no_portal_id_set = no_portal_id_set
    //state.error = {}
  },
  [SAVE_ONBOARDING_STATUS] (state, onboarding_status) {
    state.onboarding_status = onboarding_status
    //state.error = {}
  },
  [SAVE_AGENCY_PORTALS] (state, agency_portals) {
    state.agency_portals = agency_portals
    //state.error = {}
  },
  [SAVE_MY_PORTALS] (state, my_portals) {
    state.my_portals = my_portals
    //state.error = {}
  },
  [SAVE_SOCIAL_ACCOUNT] (state, social_account) {
    state.social_account = social_account
    //state.error = {}
  },
  [SAVE_SOCIAL_ACCOUNTS] (state, social_accounts) {
    state.social_accounts = social_accounts
    //state.error = {}
  },
  [SAVE_DISCONNECT_SOCIAL_ACCOUNT] (state, disconnect_social_account) {
    state.disconnect_social_account = disconnect_social_account
    //state.error = {}
  },
  [SAVE_LINKEDIN_OAUTH_CREDENTIALS] (state, linkedin_oauth_credentials) {
    state.linkedin_oauth_credentials = linkedin_oauth_credentials
    //state.error = {}
  },
  [SAVE_MICROSOFT_OAUTH_CREDENTIALS] (state, microsoft_oauth_credentials) {
    state.microsoft_oauth_credentials = microsoft_oauth_credentials
    //state.error = {}
  },
  [SAVE_LINKEDIN_ACCESS_TOKEN] (state, linkedin_access_token) {
    state.linkedin_access_token = linkedin_access_token
    //state.error = {}
  },
  [SAVE_LINKEDIN_MEMBER_PROFILE] (state, linkedin_member_profile) {
    state.linkedin_member_profile = linkedin_member_profile
    //state.error = {}
  },
  [SAVE_LINKEDIN_MEMBER_PROFILE_PICTURE] (state, linkedin_member_profile_picture) {
    state.linkedin_member_profile_picture = linkedin_member_profile_picture
    //state.error = {}
  },
  [SAVE_LINKEDIN_MEMBER_EMAIL] (state, linkedin_member_email) {
    state.linkedin_member_email = linkedin_member_email
    //state.error = {}
  },
  [SAVE_LINKEDIN_MEMBER] (state, linkedin_member) {
    state.linkedin_member = linkedin_member
    //state.error = {}
  },
  [SAVE_HUBSPOT_OAUTH_CREDENTIALS] (state, hubspot_oauth_credentials) {
    state.hubspot_oauth_credentials = hubspot_oauth_credentials
    //state.error = {}
  },
  [SAVE_HUBSPOT_ACCESS_TOKEN] (state, hubspot_access_token) {
    state.hubspot_access_token = hubspot_access_token
    //state.error = {}
  },
  [SAVE_HUBSPOT_USERS] (state, hubspot_users) {
    state.hubspot_users = hubspot_users
    //state.error = {}
  },
  [SAVE_EMAIL_AVAILABLE] (state, email_available) {
    state.email_available = email_available
    //state.error = {}
  },
  [SAVE_USER_REGISTERED_BEFORE] (state, user_registered_before) {
    state.user_registered_before = user_registered_before
    //state.error = {}
  },
  [SAVE_LOGOUT] (state, logout) {
    state.logout = logout
  },
  [ACTION_BEGIN](state, action_name) {
    state.error[action_name] = false;
  },
  [ACTION_FAILURE](state, action_name) {
    state.error[action_name] = true;
  },
  [ACTION_SUCCESS](state, action_name) {
    state.error[action_name] = false;
  },
  [SAVE_GOOGLE_OAUTH_CREDENTIALS] (state, google_oauth_credentials) {
    state.google_oauth_credentials = google_oauth_credentials
    //state.error = {}
  },
  [RESET_STATE_ON_LOGOUT] (state) {
    state.user_portal_settings_id = null
    state.user_id = null
    state.user = {
      first_name: '',
      last_name: '',
    }
    state.portal_id = 'not_set'
    state.has_perm_for_portal = false
    state.no_portal_id_set = true
    state.onboarding_status = ''
    store.dispatch('accounts/delete_session', { key: 'portal_id' })
    store.dispatch('accounts/delete_session', { key: 'portal_name' })
    store.dispatch('accounts/delete_session', { key: 'agency_portal' })
  },
  [SAVE_CREATED_PORTAL] (state, created_portal) {
    state.created_portal = created_portal
    //state.error = {}
  },
  [SAVE_ADMIN_VIEW_AS_USER_PORTAL_SETTINGS] (state, admin_view_as_user_portal_settings) {
    state.admin_view_as_user_portal_settings = admin_view_as_user_portal_settings
  },
  [SAVE_HAS_OBJECT_PERMISSION] (state, has_object_permission) {
    state.has_object_permission = has_object_permission
  },
  [SAVE_USER_PROFILE_SLUG] (state, user_profile_slug) {
    state.user_profile_slug = user_profile_slug
  },
  [SAVE_FLATFILE_KEY] (state, flatfile_key) {
    state.flatfile_key = flatfile_key
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}