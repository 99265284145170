import session from './session';

export default {
  post_search_query(search_query, current_page, per_page) {
    return session.post('/api/search_query/', { search_query, current_page, per_page });
  },
  post_public_search_query(search_query, current_page, per_page) {
    return session.post('/api/public_search_query/', { search_query, current_page, per_page });
  },
  get_campaign_statistics(portal_id, campaign_id) {
    return session.get('/api/campaign_statistics/', { params: {portal_id, campaign_id} });
  },
  get_dashboard_statistics(portal_id) {
    return session.get('/api/dashboard_statistics/', { params: {portal_id} });
  },
  get_email_statistics(email_template_id) {
    return session.get('/api/email_statistics/', { params: {email_template_id} });
  },
  get_linkedin_message_statistics(linkedin_message_template_id) {
    return session.get('/api/linkedin_message_statistics/', { params: {linkedin_message_template_id} });
  },
  get_campaign_chart(portal_id, campaign_id) {
    return session.get('/api/campaign_chart/', { params: {portal_id, campaign_id} });
  },
  get_dashboard_chart(portal_id, statistics_from, statistics_to) {
    return session.get('/api/dashboard_chart/', { params: {portal_id, statistics_from, statistics_to} });
  },
  get_emails_sent_chart(portal_id, statistics_from, statistics_to) {
    return session.get('/api/emails_sent_chart/', { params: {portal_id, statistics_from, statistics_to} });
  },
  get_email_chart(email_template_id) {
    return session.get('/api/email_chart/', { params: {email_template_id} });
  },
  get_linkedin_message_chart(linkedin_message_template_id) {
    return session.get('/api/linkedin_message_chart/', { params: {linkedin_message_template_id} });
  },
  get_persons(params) {
    return session.get('/api/persons/', { params: params });
  },
  get_nested_persons(params) {
    return session.get('/api/nested/persons/', { params: params });
  },
  post_person(data) {
    return session.post('/api/persons/', data);
  },
  patch_person(person_id, data) {
    return session.patch(`/api/persons/${person_id}/`, data);
  },
  get_filter_queries(params) {
    return session.get('/api/filterqueries/', { params: params });
  },
  get_nested_filter_queries(params) {
    return session.get('/api/nested/filterqueries/', { params: params });
  },
  get_filter_query(filter_query_id) {
    return session.get(`/api/filterqueries/${filter_query_id}/`);
  },
  get_nested_filter_query(filter_query_id) {
    return session.get(`/api/nested/filterqueries/${filter_query_id}/`);
  },
  post_filter_query(data) {
    return session.post('/api/filterqueries/', data);
  },
  delete_filter_query(filter_query_id) {
    return session.delete(`/api/filterqueries/${filter_query_id}/`);
  },
  patch_filter_query(filter_query_id, data) {
    return session.patch(`/api/filterqueries/${filter_query_id}/`, data);
  },
  get_or_queries(params) {
    return session.get('/api/orqueries/', { params: params });
  },
  get_or_query(or_query_id) {
    return session.get(`/api/orqueries/${or_query_id}/`);
  },
  post_or_query(data) {
    return session.post('/api/orqueries/', data);
  },
  delete_or_query(or_query_id) {
    return session.delete(`/api/orqueries/${or_query_id}/`);
  },
  patch_or_query(or_query_id, data) {
    return session.patch(`/api/orqueries/${or_query_id}/`, data);
  },
  get_and_queries(params) {
    return session.get('/api/andqueries/', { params: params });
  },
  get_and_query(and_query_id) {
    return session.get(`/api/andqueries/${and_query_id}/`);
  },
  post_and_query(data) {
    return session.post('/api/andqueries/', data);
  },
  delete_and_query(and_query_id) {
    return session.delete(`/api/andqueries/${and_query_id}/`);
  },
  patch_and_query(and_query_id, data) {
    return session.patch(`/api/andqueries/${and_query_id}/`, data);
  },
  get_investment_deal(investment_deal_id) {
    return session.get(`/api/investmentdeals/${investment_deal_id}/`);
  },
  get_nested_investment_deal(investment_deal_id) {
    return session.get(`/api/nested/investmentdeals/${investment_deal_id}/`);
  },
  get_public_nested_investment_deal(investment_deal_id) {
    return session.get(`/api/public/nested/investmentdeals/${investment_deal_id}/`);
  },
  get_investment_deals(params) {
    return session.get('/api/investmentdeals/', { params: params });
  },
  get_nested_investment_deals(params) {
    return session.get('/api/nested/investmentdeals/', { params: params });
  },
  get_public_nested_investment_deals(params) {
    return session.get('/api/public/nested/investmentdeals/', { params: params });
  },
  get_fast_public_nested_investment_deals(params) {
    return session.get('/api/fast/public/nested/investmentdeals/', { params: params });
  },
  post_investment_deal(data) {
    return session.post('/api/investmentdeals/', data);
  },
  post_nested_investment_deal(data) {
    return session.post('/api/nested/investmentdeals/', data);
  },
  patch_investment_deal(investment_deal_id, data) {
    return session.patch(`/api/investmentdeals/${investment_deal_id}/`, data);
  },
  get_investment_deal_team_member(investment_deal_team_member_id) {
    return session.get(`/api/investmentdealteammembers/${investment_deal_team_member_id}/`);
  },
  get_investment_deal_team_members(params) {
    return session.get('/api/investmentdealteammembers/', { params: params });
  },
  post_nested_investment_deal_team_member(data) {
    return session.post('/api/nested/investmentdealteammembers/', data);
  },
  post_investment_deal_team_member(data) {
    return session.post('/api/investmentdealteammembers/', data);
  },
  patch_investment_deal_team_member(investment_deal_team_member_id, data) {
    return session.patch(`/api/investmentdealteammembers/${investment_deal_team_member_id}/`, data);
  },
  get_investment_types(params) {
    return session.get('/api/investmenttypes/', { params: params });
  },
  get_categories(params) {
    return session.get('/api/categories/', { params: params });
  },
  get_investment_target_annual_gross_revenue_usds(params) {
    return session.get('/api/investmenttargetannualgrossrevenues/', { params: params });
  },
  get_investment_target_annual_ebitda_usds(params) {
    return session.get('/api/investmenttargetannualebitda/', { params: params });
  },
  get_linkedin_industries(params) {
    return session.get('/api/linkedinindustries/', { params: params });
  },
  get_navbar_search_autocomplete(query, portal_id) {
    return session.get('/api/navbar-search-autocomplete/', { params: {
      q: query,
      portal_id: portal_id,
    }});
  },
  get_category_autocomplete(query) {
    return session.get('/api/category-autocomplete/', { params: {
      q: query,
    }});
  },
  get_investment_type_autocomplete(query) {
    return session.get('/api/investmenttype-autocomplete/', { params: {
      q: query,
    }});
  },
  get_funding_round_type_autocomplete(query) {
    return session.get('/api/fundingroundtype-autocomplete/', { params: {
      q: query,
    }});
  },
  put_investment_deal_team_member_profile_picture(profile_picture, filename, content_type, investment_deal_team_member_id) {
    return session.put(`/api/upload/investment_deal_team_member/${investment_deal_team_member_id}/profile_picture/${filename}`, profile_picture, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  put_investment_deal_cover_photo(deal_cover_photo, filename, content_type, investment_deal_id) {
    return session.put(`/api/upload/investment_deal/${investment_deal_id}/deal_cover_photo/${filename}`, deal_cover_photo, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  put_investment_deal_pitch_deck(pitch_deck, filename, content_type, investment_deal_id) {
    return session.put(`/api/upload/investment_deal/${investment_deal_id}/pitch_deck/${filename}`, pitch_deck, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  put_investment_deal_executive_summary(executive_summary, filename, content_type, investment_deal_id) {
    return session.put(`/api/upload/investment_deal/${investment_deal_id}/executive_summary/${filename}`, executive_summary, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  put_investment_deal_due_diligence_package(due_diligence_package, filename, content_type, investment_deal_id) {
    return session.put(`/api/upload/investment_deal/${investment_deal_id}/due_diligence_package/${filename}`, due_diligence_package, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  post_email_list_lead(data) {
    return session.post('/api/email_list_lead/', data);
  },
  post_make_investment(data) {
    return session.post('/api/make_investment/', data);
  },
  get_available_portals_to_user(params) {
    return session.get('/api/available_portals_to_user/', { params: params });
  },
  post_user_emails_on_portal(data) {
    return session.post('/api/user_emails_on_portal/', data);
  },
  post_linkedin_person_collect_url_request(data) {
    return session.post('/api/linkedin_person_collect_url_request/', data);
  },
  post_demo_form(data) {
    return session.post('/api/demo_form/', data);
  },
  post_brokerage_partnership_form(data) {
    return session.post('/api/brokerage_partnership_form/', data);
  },
  get_person_profile(params) {
    return session.get('/api/custom/person_profile/', { params: params });
  },
  get_organization_profile(params) {
    return session.get('/api/custom/organization_profile/', { params: params });
  },
  post_delete_investment_deal(data) {
    return session.post('/api/delete_investment_deal/', data);
  },
  post_investment_focuses(data) {
    return session.post('/api/post_investment_focuses/', data);
  },
};