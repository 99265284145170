import 'regenerator-runtime/runtime'

import Vue from 'vue';

//important to import it here first so that other CSS overrides the styling
import vuetify from '@/plugins/vuetify'

import DashboardPlugin from './plugins/dashboard-plugin';
import FbSdk from './plugins/fb-sdk';
import App from './App.vue';

// router setup
import router from './routes/router';

//store
import store from './store'

//wait - handle load
import VueWait from 'vue-wait'
Vue.use(VueWait)

//to blur elements
import vBlur from 'v-blur'
Vue.use(vBlur)

//vue loading overlay (will use for fullscreen)
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {
  container: null,
  canCancel: false,
  color: '#172b4d',
  loader: 'spinner',
  width: 55,
  height: 55,
  backgroundColor: '#fff',
  opacity: 0.5,
  zIndex: 999999,
});

//vue-multiselect
import 'vue-multiselect/dist/vue-multiselect.min.css';

//svg-progress-bar
import svg from 'svg-progress-bar'
Vue.use(svg,{componentName: 'svg-progress-bar'})

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(FbSdk);

//calendly
import VueCalendly from 'vue-calendly';
Vue.use(VueCalendly);

//vue devtools
Vue.config.devtools = process.env.NODE_ENV === 'development'

//Google Tag Manager
import VueGtm from 'vue-gtm';
Vue.use(VueGtm, {
  id: 'GTM-T8XTFZX', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  /*queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview:'env-4',
    gtm_cookies_win:'x'
  },*/
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  vuetify,
  router,
  store,
  wait: new VueWait({
    // Defaults values are following:
    useVuex: true,              // Uses Vuex to manage wait state
    vuexModuleName: 'wait',      // Vuex module name
    registerComponent: true,     // Registers `v-wait` component
    componentName: 'v-wait',     // <v-wait> component name, you can set `my-loader` etc.
    registerDirective: true,     // Registers `v-wait` directive
    directiveName: 'wait',       // <span v-wait /> directive name, you can set `my-loader` etc.
  }),
});