import support from '@/api/support';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
       } from './types'

const state = {
  error: {},
  loading: false,
}
const getters = {
  error (state) {
    return state.error;
  },
}
const actions = {
  post_support_ticket({ commit }, payload) {
    return support
      .post_support_ticket(payload)
      .then(({data}) => {
        //commit(SAVE_REFERRAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}