// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules

export const SAVE_LINKEDIN_USER_SETTING = "SAVE_LINKEDIN_USER_SETTING"
export const SAVE_LINKEDIN_USER_SETTINGS = "SAVE_LINKEDIN_USER_SETTINGS"
export const SAVE_HUBSPOT_USER_SETTING = "SAVE_HUBSPOT_USER_SETTING"
export const SAVE_HUBSPOT_USER_SETTINGS = "SAVE_HUBSPOT_USER_SETTINGS"

export const SAVE_ALL_USER_PORTAL_SETTINGS = "SAVE_ALL_USER_PORTAL_SETTINGS"


export const SAVE_MARKETING_QUESTIONNAIRES = "SAVE_MARKETING_QUESTIONNAIRES"
export const SAVE_EMAIL_SETTINGS = "SAVE_EMAIL_SETTINGS"
export const SAVE_CALENDAR_SETTINGS = "SAVE_CALENDAR_SETTINGS"
export const SAVE_MARKETING_QUESTIONNAIRE = "SAVE_MARKETING_QUESTIONNAIRE"
export const SAVE_EMAIL_SETTING = "SAVE_EMAIL_SETTING"
export const SAVE_CALENDAR_SETTING = "SAVE_CALENDAR_SETTING"

export const SAVE_COPY_GENERATOR = "SAVE_COPY_GENERATOR"
