// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules
export const SAVE_SESSION = "SAVE_SESSION"
export const SAVE_COOKIE = "SAVE_COOKIE"
export const SAVE_PERM = "SAVE_PERM"
export const SAVE_USER_PORTAL_SETTINGS_ID = "SAVE_USER_PORTAL_SETTINGS_ID"
export const SAVE_USER_ID = "SAVE_USER_ID"
export const SAVE_USER = "SAVE_USER"
export const SAVE_PORTAL_ID = "SAVE_PORTAL_ID"
export const SAVE_HAS_PERM_FOR_PORTAL = "SAVE_HAS_PERM_FOR_PORTAL"
export const SAVE_NO_PORTAL_ID_SET = "SAVE_NO_PORTAL_ID_SET"
export const SAVE_ONBOARDING_STATUS = "SAVE_ONBOARDING_STATUS"
export const SAVE_AGENCY_PORTALS = "SAVE_AGENCY_PORTALS"
export const SAVE_MY_PORTALS = "SAVE_MY_PORTALS"

export const SAVE_SOCIAL_ACCOUNT = "SAVE_SOCIAL_ACCOUNT"
export const SAVE_SOCIAL_ACCOUNTS = "SAVE_SOCIAL_ACCOUNTS"
export const SAVE_DISCONNECT_SOCIAL_ACCOUNT = "SAVE_DISCONNECT_SOCIAL_ACCOUNT"
export const SAVE_LINKEDIN_OAUTH_CREDENTIALS = "SAVE_LINKEDIN_OAUTH_CREDENTIALS"
export const SAVE_MICROSOFT_OAUTH_CREDENTIALS = "SAVE_MICROSOFT_OAUTH_CREDENTIALS"

export const SAVE_LINKEDIN_ACCESS_TOKEN = "SAVE_LINKEDIN_ACCESS_TOKEN"
export const SAVE_LINKEDIN_MEMBER_PROFILE = "SAVE_LINKEDIN_MEMBER_PROFILE"
export const SAVE_LINKEDIN_MEMBER_PROFILE_PICTURE = "SAVE_LINKEDIN_MEMBER_PROFILE_PICTURE"
export const SAVE_LINKEDIN_MEMBER_EMAIL = "SAVE_LINKEDIN_MEMBER_EMAIL"

export const SAVE_LINKEDIN_MEMBER = "SAVE_LINKEDIN_MEMBER"

export const SAVE_HUBSPOT_OAUTH_CREDENTIALS = "SAVE_HUBSPOT_OAUTH_CREDENTIALS"
export const SAVE_HUBSPOT_ACCESS_TOKEN = "SAVE_HUBSPOT_ACCESS_TOKEN"
export const SAVE_HUBSPOT_USERS = "SAVE_HUBSPOT_USERS"

export const SAVE_EMAIL_AVAILABLE = "SAVE_EMAIL_AVAILABLE"
export const SAVE_USER_REGISTERED_BEFORE = "SAVE_USER_REGISTERED_BEFORE"

export const SAVE_LOGOUT = "SAVE_LOGOUT"

export const ACTION_BEGIN = "ACTION_BEGIN"
export const ACTION_SUCCESS = "ACTION_SUCCESS"
export const ACTION_FAILURE = "ACTION_FAILURE"

export const SAVE_GOOGLE_OAUTH_CREDENTIALS = "SAVE_GOOGLE_OAUTH_CREDENTIALS"

export const RESET_STATE_ON_LOGOUT = "RESET_STATE_ON_LOGOUT"
export const SAVE_CREATED_PORTAL = "SAVE_CREATED_PORTAL"

export const SAVE_ADMIN_VIEW_AS_USER_PORTAL_SETTINGS = "SAVE_ADMIN_VIEW_AS_USER_PORTAL_SETTINGS"

export const SAVE_HAS_OBJECT_PERMISSION = "SAVE_HAS_OBJECT_PERMISSION"

export const SAVE_USER_PROFILE_SLUG = "SAVE_USER_PROFILE_SLUG"

export const SAVE_FLATFILE_KEY = "SAVE_FLATFILE_KEY"