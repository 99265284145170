import session from './session';

export default {
  post_toggle_completed_training_module(data) {
    return session.post('/api/training/toggle_completed_training_module/', data);
  },
  get_loaded_training(params) {
    return session.get('/api/training/get_loaded_training/', { params: params });
  },
  post_ppm_form(data) {
    return session.post('/api/training/private_placement_memorandum/', data, {
      'Authorization': session.defaults.headers.Authorization,
      'responseType': 'blob',
    });
  },
};