// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules


export const SAVE_LOADED_TRAINING = "SAVE_LOADED_TRAINING"
export const TOGGLE_COMPLETED_TRAINING_MODULE = "TOGGLE_COMPLETED_TRAINING_MODULE"
export const SAVE_PPM_FORM = "SAVE_PPM_FORM"

