<template>
  <div class="payment-options">
  <p class="lead">I'd like to pay using:</p>

  <ul v-if="payment_plan" class="no-bullet row" id="payment_option_list">
    <li :class="single_payment_selected == false ? 'columns large-6' : 'columns large-6 active'" id="single-payment-li">
      <label for="single-payment" style="min-width:100%;">
        <input type="radio" id="single-payment" name="payment_type" v-model="model" value="1" :checked="single_payment_selected == false ? false : true" style="display:none;" @click="single_payment_selected=true">
        <strong>1x payment of USD $2,497</strong>
        Save USD $488 by paying upfront (recommended).
      </label>
    </li>
    <li :class="single_payment_selected == true ? 'columns large-6' : 'columns large-6 active'" id="payment-plan-li">
      <label for="payment-plan" style="min-width:100%;">
        <input type="radio" id="payment-plan" name="payment_type" v-model="model" value="2" :checked="single_payment_selected == true ? false : true" style="display:none;" @click="single_payment_selected=false">
        <strong>5x payment of USD $597</strong>
        5 monthly payments billed every 30 days.
      </label>
    </li>
  </ul>
  <ul v-else class="no-bullet row" id="payment_option_list">
    <li :class="single_payment_selected == true ? 'columns large-6 active' : 'columns large-6'" id="single-payment-li">
      <label for="single-payment" style="min-width:100%;">
        <input type="radio" id="single-payment" name="payment_type" v-model="model" value="1" :checked="single_payment_selected == true ? true : false" style="display:none;" @click="single_payment_selected=true">
        <strong>1x payment of USD $2,497</strong>
        Save USD $488 by paying upfront (recommended).
      </label>
    </li>
    <li :class="single_payment_selected == false ? 'columns large-6 active' : 'columns large-6'" id="payment-plan-li">
      <label for="payment-plan" style="min-width:100%;">
        <input type="radio" id="payment-plan" name="payment_type" v-model="model" value="2" :checked="single_payment_selected == false ? true : false" style="display:none;" @click="single_payment_selected=false">
        <strong>5x payment of USD $597</strong>
        5 monthly payments billed every 30 days.
      </label>
    </li>
  </ul>
  </div>
</template>
<script>
  export default {
    name: 'prod-payment-plan-radio',
    props: {
      value: {
        type: [String, Boolean],
        description: 'Radio value'
      },
      payment_plan: {
        type: [Boolean],
      },
    },
    data() {
      return {
        //to change selected
        single_payment_selected: undefined,
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },
    created() {
      this.single_payment_selected = !this.payment_plan
      if (this.single_payment_selected) {
        this.model = '1'
      } else {
        this.model = '2'
      }
    }
  };
</script>

<style scoped>
.payment-options {
    margin-top: 10px;
    margin-bottom: 40px;
}
.no-bullet {
    margin-left: 0;
    list-style: none;
}
.row {
    padding-left: 0px;
}
.row .row.collapse {
    margin-right: 0;
    margin-left: 0;
}
.column, .columns {
    width: 100%;
    float: left;
    padding-right: .625rem;
    padding-left: .625rem;
}
li {
    font-size: inherit;
}
.payment-options li.active label:before {
    color: #2d99e2;
}
.payment-options li:first-child label {
    border-radius: 5px 5px 0 0;
}
.payment-options label:before {
    content: "\f058";
    color: #e5e7e9;
    font-family: FontAwesome;
    display: block;
    margin-bottom: 12px;
}
.payment-options label {
    border: 2px solid #f2f3f4;
    text-align: center;
    padding: 20px;
    margin-bottom: 0;
    text-transform: none;
    line-height: 1.4;
    font-weight: 400;
    cursor: pointer;
    color: #bdc2c7;
    height: 100%;
}
.payment-options li.active label {
    border-color: #2d99e2;
    color: #2d99e2;
    background: #f7fbfe;
}
</style>