import billing from '@/api/billing';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_CUSTOMER_COMPANIES,
        SAVE_CUSTOMER_COMPANY,
        SAVE_USERS_AND_LICENSES,
        SAVE_STRIPE_PUBLIC_KEY,
        SAVE_STRIPE_CARDS,
        SAVE_PURCHASE,
        SAVE_PURCHASE_ERROR,
        SAVE_PRODUCTS,
        SAVE_PLANS,
        SAVE_IF_NEW_ACCOUNT_DISCOUNT,
        SAVE_TRIAL_UPGRADE,
        SAVE_TRIAL_UPGRADE_MESSAGE,
        SAVE_CANCEL_TRIAL,
        SAVE_EVERSIGN_URL,
       } from './types'

const state = {
  error: {},
  loading: false,
  customer_companies: [],
  customer_company: {},
  users_and_licenses: {
    assign_license_form: {
      licenses: [],
      portals: [],
    },
    buy_license_form: {
      product: {
        id: '',
        name: '',
      },
      amount: '',
      currency: '',
      billing_scheme: '',
      interval: '',
      interval_count: '',
      tiers_mode: '',
      usage_type: '',
    },
    remove_license_form: {
      remove_choices: [],
    },
    reactivate_license_form: {
      reactivate_choices: [],
    },
    users_settings: {
      total_licenses: '',
      available_licenses_count: '',
      canceled_licenses_count: '',
      assigned_licenses: [],
    },
    has_perm_and_is_customer_user: false,
    is_trial_user: true,
  },
  stripe_public_key: null,
  stripe_cards: [],
  purchase: {},
  purchase_error: '',
  products: [],
  plans: [{
    product: {
      name: '',
    },
    currency: 'USD',
    amount: '',
  }],
  if_new_account_discount: false,
  trial_upgrade: {
    payment_plan: null,
  },
  trial_upgrade_message: '',
  cancel_trial: '',
  eversign_url: '',
}
const getters = {
  error (state) {
    return state.error;
  },
  customer_companies (state) {
    return state.customer_companies
  },
  customer_company (state) {
    return state.customer_company
  },
  users_and_licenses (state) {
    return state.users_and_licenses
  },
  stripe_public_key (state) {
    return state.stripe_public_key
  },
  stripe_cards (state) {
    return state.stripe_cards
  },
  purchase (state) {
    return state.purchase
  },
  purchase_error (state) {
    return state.purchase_error
  },
  products (state) {
    return state.products
  },
  plans (state) {
    return state.plans
  },
  if_new_account_discount (state) {
    return state.if_new_account_discount
  },
  trial_upgrade (state) {
    return state.trial_upgrade
  },
  trial_upgrade_message (state) {
    return state.trial_upgrade_message
  },
  cancel_trial (state) {
    return state.cancel_trial
  },
  eversign_url (state) {
    return state.eversign_url
  },
}
const actions = {
  get_customer_companies({ commit }, { params }) {
    return billing
      .get_customer_companies(params)
      .then(({data}) => {
        commit(SAVE_CUSTOMER_COMPANIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_customer_company({ commit }, { customer_company_id }) {
    return billing
      .get_customer_company(customer_company_id)
      .then(({data}) => {
        commit(SAVE_CUSTOMER_COMPANY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_customer_company({ commit }, { customer_company_id }) {
    return billing
      .delete_customer_company(customer_company_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_customer_company({ commit }, payload) {
    return billing
      .post_customer_company(payload)
      .then(({data}) => {
        commit(SAVE_CUSTOMER_COMPANY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_customer_company({ commit }, { customer_company_id, payload }) {
    return billing
      .patch_customer_company(customer_company_id, payload)
      .then(({data}) => {
        commit(SAVE_CUSTOMER_COMPANY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_users_and_licenses({ commit }) {
    return billing
      .get_users_and_licenses()
      .then(({data}) => {
        commit(SAVE_USERS_AND_LICENSES, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_assign_license({ commit }, { form }) {
    return billing
      .post_assign_license(form)
      .then(() => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_unassign_license({ commit }, { license_id }) {
    return billing
      .post_unassign_license(license_id)
      .then(() => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_remove_license({ commit }, { form }) {
    return billing
      .post_remove_license(form)
      .then(() => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_reactivate_license({ commit }, { form }) {
    return billing
      .post_reactivate_license(form)
      .then(() => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_cancel_trial({ commit }) {
    return billing
      .post_cancel_trial()
      .then(({data}) => {
        commit(SAVE_CANCEL_TRIAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_stripe_public_key({ commit }) {
    return billing
      .get_stripe_public_key()
      .then(({data}) => {
        commit(SAVE_STRIPE_PUBLIC_KEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_stripe_cards({ commit }) {
    return billing
      .get_stripe_cards()
      .then(({data}) => {
        commit(SAVE_STRIPE_CARDS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_add_stripe_card({ commit }, { stripeToken }) {
    return billing
      .post_add_stripe_card(stripeToken)
      .then(() => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_purchase({ commit }) {
    return billing
      .get_purchase()
      .then(({data}) => {
        commit(SAVE_PURCHASE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_purchase({ commit }, payload) {
    return billing
      .post_purchase(payload)
      .then(({data}) => {
        if ('error' in data) {
          commit(SAVE_PURCHASE_ERROR, data.error)
        }
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_products({ commit }, { params }) {
    return billing
      .get_nested_products(params)
      .then(({data}) => {
        commit(SAVE_PRODUCTS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_plans({ commit }, { params }) {
    return billing
      .get_nested_plans(params)
      .then(({data}) => {
        commit(SAVE_PLANS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_portal({ commit }, { portal_id, payload }) {
    return billing
      .patch_portal(portal_id, payload)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_if_new_account_discount({ commit }) {
    return billing
      .get_if_new_account_discount()
      .then(({data}) => {
        commit(SAVE_IF_NEW_ACCOUNT_DISCOUNT, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_trial_upgrade({ commit }, { params }) {
    return billing
      .get_trial_upgrade(params)
      .then(({data}) => {
        commit(SAVE_TRIAL_UPGRADE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_trial_upgrade({ commit }, payload) {
    return billing
      .post_trial_upgrade(payload)
      .then(({data}) => {
        commit(SAVE_TRIAL_UPGRADE_MESSAGE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_eversign_url({ commit }, { params }) {
    return billing
      .get_eversign_url(params)
      .then(({data}) => {
        commit(SAVE_EVERSIGN_URL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_CUSTOMER_COMPANIES] (state, customer_companies) {
    state.customer_companies = customer_companies
    state.error = {}
  },
  [SAVE_CUSTOMER_COMPANY] (state, customer_company) {
    state.customer_company = customer_company
    state.error = {}
  },
  [SAVE_USERS_AND_LICENSES] (state, users_and_licenses) {
    state.users_and_licenses = users_and_licenses
    state.error = {}
  },
  [SAVE_STRIPE_PUBLIC_KEY] (state, stripe_public_key) {
    state.stripe_public_key = stripe_public_key
    state.error = {}
  },
  [SAVE_STRIPE_CARDS] (state, stripe_cards) {
    state.stripe_cards = stripe_cards
    state.error = {}
  },
  [SAVE_PURCHASE] (state, purchase) {
    state.purchase = purchase
    state.purchase_error = ''
    state.error = {}
  },
  [SAVE_PURCHASE_ERROR] (state, purchase_error) {
    state.purchase_error = purchase_error
  },
  [SAVE_PRODUCTS] (state, products) {
    state.products = products
    state.error = {}
  },
  [SAVE_PLANS] (state, plans) {
    state.plans = plans
    state.error = {}
  },
  [SAVE_IF_NEW_ACCOUNT_DISCOUNT] (state, if_new_account_discount) {
    state.if_new_account_discount = if_new_account_discount
    state.error = {}
  },
  [SAVE_TRIAL_UPGRADE] (state, trial_upgrade) {
    state.trial_upgrade = trial_upgrade
    state.error = {}
  },
  [SAVE_TRIAL_UPGRADE_MESSAGE] (state, trial_upgrade_message) {
    state.trial_upgrade_message = trial_upgrade_message
    state.error = {}
  },
  [SAVE_CANCEL_TRIAL] (state, cancel_trial) {
    state.cancel_trial = cancel_trial
    state.error = {}
  },
  [SAVE_EVERSIGN_URL] (state, eversign_url) {
    state.eversign_url = eversign_url
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}