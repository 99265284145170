// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules
export const SAVE_OUTBOXES = "SAVE_OUTBOXES"
export const SAVE_OUTBOX = "SAVE_OUTBOX"
export const SAVE_EMAIL_TEMPLATES = "SAVE_EMAIL_TEMPLATES"
export const SAVE_EMAIL_TEMPLATE = "SAVE_EMAIL_TEMPLATE"
export const SAVE_TOTAL_EMAIL_TEMPLATES = "SAVE_TOTAL_EMAIL_TEMPLATES"

export const SAVE_TEST_SMTP_CONNECTION = "SAVE_TEST_SMTP_CONNECTION"

export const SAVE_LATEST_EMAILS_SENT = "SAVE_LATEST_EMAILS_SENT"
export const SAVE_TOTAL_EMAILS_SENT = "SAVE_TOTAL_EMAILS_SENT"
