import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '@/store'

Vue.use(Router);

const router = new Router({
  routes, // short for routes: routes
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

// Adding a global navigation guard
/*router.beforeEach((to, from, next) => {
  store.commit('accounts/setPreviousRoute', from);
  next();
});*/

export default router;
