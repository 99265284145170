// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules
export const SAVE_CUSTOMER_COMPANIES = "SAVE_CUSTOMER_COMPANIES"
export const SAVE_CUSTOMER_COMPANY = "SAVE_CUSTOMER_COMPANY"
export const SAVE_USERS_AND_LICENSES = "SAVE_USERS_AND_LICENSES"
export const SAVE_STRIPE_PUBLIC_KEY = "SAVE_STRIPE_PUBLIC_KEY"
export const SAVE_STRIPE_CARDS = "SAVE_STRIPE_CARDS"
export const SAVE_PURCHASE = "SAVE_PURCHASE"
export const SAVE_PURCHASE_ERROR = "SAVE_PURCHASE_ERROR"

export const SAVE_PRODUCTS = "SAVE_PRODUCTS"
export const SAVE_PLANS = "SAVE_PLANS"

export const SAVE_IF_NEW_ACCOUNT_DISCOUNT = "SAVE_IF_NEW_ACCOUNT_DISCOUNT"

export const SAVE_TRIAL_UPGRADE = "SAVE_TRIAL_UPGRADE"
export const SAVE_TRIAL_UPGRADE_MESSAGE = "SAVE_TRIAL_UPGRADE_MESSAGE"

export const SAVE_CANCEL_TRIAL = "SAVE_CANCEL_TRIAL"

export const SAVE_EVERSIGN_URL = "SAVE_EVERSIGN_URL"