import axios from 'axios';
import Vue from 'vue'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

const session = Vue.axios.create({
	xsrfCookieName: CSRF_COOKIE_NAME,
	xsrfHeaderName: CSRF_HEADER_NAME,
});

export default session;