import central_db from '@/api/central_db';
import session from '@/api/session';

import { 
  LOADING_START,
  LOADING_END,
  SAVE_ERROR,
  SAVE_PERSON_INVESTORS,
  SAVE_PERSONS,
  SAVE_PERSON,
  SAVE_TOTAL_PERSONS,
  SAVE_ORGANIZATIONS,
  SAVE_ORGANIZATION_INVESTORS,
  SAVE_CAMPAIGN_STATISTICS,
  SAVE_DASHBOARD_STATISTICS,
  SAVE_EMAIL_STATISTICS,
  SAVE_LINKEDIN_MESSAGE_STATISTICS,
  SAVE_CAMPAIGN_CHART,
  SAVE_DASHBOARD_CHART,
  SAVE_EMAILS_SENT_CHART,
  SAVE_EMAIL_CHART,
  SAVE_LINKEDIN_MESSAGE_CHART,
  SAVE_FILTER_QUERIES,
  SAVE_FILTER_QUERY,
  SAVE_OR_QUERIES,
  SAVE_OR_QUERY,
  SAVE_AND_QUERIES,
  SAVE_AND_QUERY,
  SAVE_CREATE_DYNAMIC_LIST_FROM_FILTER,
  SAVE_NUMBER_OF_RETRIEVES_CREATE_DYNAMIC_LIST_FROM_FILTER,
  SAVE_INVESTMENT_DEALS,
  SAVE_INVESTMENT_DEAL,
  SAVE_INVESTMENT_TYPES,
  SAVE_CATEGORIES,
  SAVE_TYPICAL_INVESTMENT_TICKET_SIZE_USDS,
  SAVE_INVESTMENT_TIMELINES,
  SAVE_INVESTMENT_TARGET_ANNUAL_GROSS_REVENUE_USDS,
  SAVE_INVESTMENT_TARGET_ANNUAL_EBITDA_USDS,
  SAVE_INVESTMENT_TARGET_NUMBER_OF_EMPLOYEES,
  SAVE_IDEAL_RETURN_PERCENTS,
  SAVE_LINKEDIN_INDUSTRIES,
  SAVE_INVESTMENT_DEAL_TEAM_MEMBER,
  SAVE_INVESTMENT_DEAL_TEAM_MEMBERS,
  SAVE_PERSON_INVESTORS_TOTAL_COUNT,
  SAVE_PERSONS_TOTAL_COUNT,
  SAVE_ORGANIZATIONS_TOTAL_COUNT,
  SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT,
  SAVE_LATEST_SEARCH_QUERY,
  SAVE_AVAILABLE_PORTALS_TO_USER,
  SAVE_USER_EMAILS_ON_PORTAL,
  SAVE_PERSON_PROFILE,
  SAVE_ORGANIZATION_PROFILE,
  SAVE_DEAL_COVER_PHOTO_URL,
  SAVE_PITCH_DECK_URL,
  SAVE_EXECUTIVE_SUMMARY_URL,
  SAVE_DUE_DILIGENCE_PACKAGE_URL,
  SAVE_CONTACTS,
  SAVE_CONTACTS_TOTAL_COUNT,
} from './types';

const state = {
  error: {},
  loading: false,
  person_investors: [],
  persons: [],
  person: {},
  organizations: [],
  organization_investors: [],
  total_persons: null,
  campaign_statistics: {},
  dashboard_statistics: {},
  email_statistics: {},
  linkedin_message_statistics: {},
  campaign_chart: {},
  dashboard_chart: {},
  emails_sent_chart: [],
  email_chart: {},
  linkedin_message_chart: {},
  filter_queries: [],
  filter_query: {},
  or_queries: [],
  or_query: {},
  and_queries: [],
  and_query: {},
  create_dynamic_list_from_filter: false,
  number_of_retrieves_create_dynamic_list_from_filter: 0,
  investment_deal: {},
  investment_deals: [],
  investment_types: [],
  categories: [],
  typical_investment_ticket_size_usds: [],
  investment_timelines: [],
  investment_target_annual_gross_revenue_usds: [],
  investment_target_annual_ebitda_usds: [],
  investment_target_number_of_employees: [],
  ideal_return_percents: [],
  linkedin_industries: [],
  investment_deal_team_member: {},
  investment_deal_team_members: [],
  person_investors_total_count: null,
  persons_total_count: null,
  organizations_total_count: null,
  organization_investors_total_count: null,
  //to send with api request if we need to do something based on last search
  latest_search_query: {},
  user_emails_on_portal: [],
  available_portals_to_user: [],
  person_profile: {},
  organization_profile: {},
  deal_cover_photo_url: '',
  pitch_deck_url: '',
  executive_summary_url: '',
  due_diligence_package_url: '',
  contacts_total_count: null,
  contacts: [],
};
const getters = {
  error (state) {
    return state.error;
  },
  person_investors (state) {
    return state.person_investors
  },
  persons (state) {
    return state.persons
  },
  total_persons (state) {
    return state.total_persons
  },
  organizations (state) {
    return state.organizations
  },
  organization_investors (state) {
    return state.organization_investors
  },
  campaign_statistics (state) {
    return state.campaign_statistics
  },
  dashboard_statistics (state) {
    return state.dashboard_statistics
  },
  email_statistics (state) {
    return state.email_statistics
  },
  linkedin_message_statistics (state) {
    return state.linkedin_message_statistics
  },
  campaign_chart (state) {
    return state.campaign_chart
  },
  dashboard_chart (state) {
    return state.dashboard_chart
  },
  emails_sent_chart (state) {
    return state.emails_sent_chart
  },
  email_chart (state) {
    return state.email_chart
  },
  linkedin_message_chart (state) {
    return state.linkedin_message_chart
  },
  filter_queries (state) {
    return state.filter_queries
  },
  filter_query (state) {
    return state.filter_query
  },
  or_queries (state) {
    return state.or_queries
  },
  or_query (state) {
    return state.or_query
  },
  and_queries (state) {
    return state.and_queries
  },
  and_query (state) {
    return state.and_query
  },
  create_dynamic_list_from_filter (state) {
    return state.create_dynamic_list_from_filter
  },
  number_of_retrieves_create_dynamic_list_from_filter (state) {
    return state.number_of_retrieves_create_dynamic_list_from_filter
  },
  investment_deal (state) {
    return state.investment_deal
  },
  investment_deals (state) {
    return state.investment_deals
  },
  investment_types (state) {
    return state.investment_types
  },
  categories (state) {
    return state.categories
  },
  typical_investment_ticket_size_usds (state) {
    return state.typical_investment_ticket_size_usds
  },
  investment_timelines (state) {
    return state.investment_timelines
  },
  investment_target_annual_gross_revenue_usds (state) {
    return state.investment_target_annual_gross_revenue_usds
  },
  investment_target_annual_ebitda_usds (state) {
    return state.investment_target_annual_ebitda_usds
  },
  investment_target_number_of_employees (state) {
    return state.investment_target_number_of_employees
  },
  ideal_return_percents (state) {
    return state.ideal_return_percents
  },
  linkedin_industries (state) {
    return state.linkedin_industries
  },
  investment_deal_team_member (state) {
    return state.investment_deal_team_member
  },
  investment_deal_team_members (state) {
    return state.investment_deal_team_members
  },
  person_investors_total_count (state) {
    return state.person_investors_total_count
  },
  persons_total_count (state) {
    return state.persons_total_count
  },
  organizations_total_count (state) {
    return state.organizations_total_count
  },
  organization_investors_total_count (state) {
    return state.organization_investors_total_count
  },
  latest_search_query (state) {
    return state.latest_search_query
  },
  user_emails_on_portal (state) {
    return state.user_emails_on_portal
  },
  available_portals_to_user (state) {
    return state.available_portals_to_user
  },
  person_profile (state) {
    return state.person_profile
  },
  organization_profile (state) {
    return state.organization_profile
  },
  deal_cover_photo_url (state) {
    return state.deal_cover_photo_url
  },
  pitch_deck_url (state) {
    return state.pitch_deck_url
  },
  executive_summary_url (state) {
    return state.executive_summary_url
  },
  due_diligence_package_url (state) {
    return state.due_diligence_package_url
  },
  contacts_total_count (state) {
    return state.contacts_total_count
  },
  contacts (state) {
    return state.contacts
  },
};
const actions = {
  //we will consider them investors if investor_types is not null on Person model
  get_search_query_person_investors({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_PERSON_INVESTORS, data.results);
        commit(SAVE_PERSON_INVESTORS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_search_query_persons({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_PERSONS, data.results);
        commit(SAVE_PERSONS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_search_query_organizations({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_ORGANIZATIONS, data.results);
        commit(SAVE_ORGANIZATIONS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_search_query_organization_investors({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_ORGANIZATION_INVESTORS, data.results);
        commit(SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_search_query_contacts({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_CONTACTS, data.results);
        commit(SAVE_CONTACTS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_search_query_person_investors({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_public_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_PERSON_INVESTORS, data.results);
        commit(SAVE_PERSON_INVESTORS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_search_query_persons({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_public_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_PERSONS, data.results);
        commit(SAVE_PERSONS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_search_query_organizations({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_public_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_ORGANIZATIONS, data.results);
        commit(SAVE_ORGANIZATIONS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_search_query_organization_investors({ commit }, { search_query, current_page, per_page }) {
    return central_db
      .post_public_search_query(search_query, current_page, per_page)
      .then(({data}) => {
        commit(SAVE_ORGANIZATION_INVESTORS, data.results);
        commit(SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT, data.total_count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_campaign_statistics({ commit }, { portal_id, campaign_id }) {
    return central_db
      .get_campaign_statistics(portal_id, campaign_id)
      .then(({data}) => {
        commit(SAVE_CAMPAIGN_STATISTICS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_dashboard_statistics({ commit }, { portal_id }) {
    return central_db
      .get_dashboard_statistics(portal_id)
      .then(({data}) => {
        commit(SAVE_DASHBOARD_STATISTICS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_statistics({ commit }, { email_template_id }) {
    return central_db
      .get_email_statistics(email_template_id)
      .then(({data}) => {
        commit(SAVE_EMAIL_STATISTICS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_message_statistics({ commit }, { linkedin_message_template_id }) {
    return central_db
      .get_linkedin_message_statistics(linkedin_message_template_id)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MESSAGE_STATISTICS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_campaign_chart({ commit }, { portal_id, campaign_id }) {
    return central_db
      .get_campaign_chart(portal_id, campaign_id)
      .then(({data}) => {
        commit(SAVE_CAMPAIGN_CHART, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_dashboard_chart({ commit }, { portal_id, statistics_from, statistics_to }) {
    return central_db
      .get_dashboard_chart(portal_id, statistics_from, statistics_to)
      .then(({data}) => {
        commit(SAVE_DASHBOARD_CHART, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_emails_sent_chart({ commit }, { portal_id, statistics_from, statistics_to }) {
    return central_db
      .get_emails_sent_chart(portal_id, statistics_from, statistics_to)
      .then(({data}) => {
        commit(SAVE_EMAILS_SENT_CHART, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_chart({ commit }, { email_template_id }) {
    return central_db
      .get_email_chart(email_template_id)
      .then(({data}) => {
        commit(SAVE_EMAIL_CHART, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_message_chart({ commit }, { linkedin_message_template_id }) {
    return central_db
      .get_linkedin_message_chart(linkedin_message_template_id)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_MESSAGE_CHART, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_persons({ commit }, { params }) {
    return central_db
      .get_persons(params)
      .then(({data}) => {
        commit(SAVE_PERSONS, data.results);
		    commit(SAVE_TOTAL_PERSONS, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_persons({ commit }, { params }) {
    return central_db
      .get_nested_persons(params)
      .then(({data}) => {
        commit(SAVE_PERSONS, data.results);
		    commit(SAVE_TOTAL_PERSONS, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_person({ commit }, payload) {
    return central_db
      .post_person(payload)
      .then(({data}) => {
        commit(SAVE_PERSON, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_person({ commit }, { person_id, payload }) {
    return central_db
      .patch_person(person_id, payload)
      .then(({data}) => {
        commit(SAVE_PERSON, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_filter_queries({ commit }, { params }) {
    return central_db
      .get_filter_queries(params)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_filter_queries({ commit }, { params }) {
    return central_db
      .get_nested_filter_queries(params)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_filter_query({ commit }, { filter_query_id }) {
    return central_db
      .get_filter_query(filter_query_id)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_filter_query({ commit }, { filter_query_id }) {
    return central_db
      .get_nested_filter_query(filter_query_id)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_filter_query({ commit }, payload) {
    return central_db
      .post_filter_query(payload)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_filter_query({ commit }, { filter_query_id, payload }) {
    return central_db
      .patch_filter_query(filter_query_id, payload)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_filter_query({ commit }, { filter_query_id }) {
    return central_db
      .delete_filter_query(filter_query_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_filter_queries({ commit }, { params }) {
    return central_db
      .get_filter_queries(params)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_filter_query({ commit }, { filter_query_id }) {
    return central_db
      .get_filter_query(filter_query_id)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_filter_query({ commit }, payload) {
    return central_db
      .post_filter_query(payload)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_filter_query({ commit }, { filter_query_id, payload }) {
    return central_db
      .patch_filter_query(filter_query_id, payload)
      .then(({data}) => {
        commit(SAVE_FILTER_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_filter_query({ commit }, { filter_query_id }) {
    return central_db
      .delete_filter_query(filter_query_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_or_queries({ commit }, { params }) {
    return central_db
      .get_or_queries(params)
      .then(({data}) => {
        commit(SAVE_OR_QUERIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_or_query({ commit }, { or_query_id }) {
    return central_db
      .get_or_query(or_query_id)
      .then(({data}) => {
        commit(SAVE_OR_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_or_query({ commit }, payload) {
    return central_db
      .post_or_query(payload)
      .then(({data}) => {
        commit(SAVE_OR_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_or_query({ commit }, { or_query_id, payload }) {
    return central_db
      .patch_or_query(or_query_id, payload)
      .then(({data}) => {
        commit(SAVE_OR_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_or_query({ commit }, { or_query_id }) {
    return central_db
      .delete_or_query(or_query_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_and_queries({ commit }, { params }) {
    return central_db
      .get_and_queries(params)
      .then(({data}) => {
        commit(SAVE_AND_QUERIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_and_query({ commit }, { and_query_id }) {
    return central_db
      .get_and_query(and_query_id)
      .then(({data}) => {
        commit(SAVE_AND_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_and_query({ commit }, payload) {
    return central_db
      .post_and_query(payload)
      .then(({data}) => {
        commit(SAVE_AND_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_and_query({ commit }, { and_query_id, payload }) {
    return central_db
      .patch_and_query(and_query_id, payload)
      .then(({data}) => {
        commit(SAVE_AND_QUERY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_and_query({ commit }, { and_query_id }) {
    return central_db
      .delete_filter_query(and_query_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_deal({ commit }, { investment_deal_id }) {
    return central_db
      .get_investment_deal(investment_deal_id)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_investment_deal({ commit }, { investment_deal_id }) {
    return central_db
      .get_nested_investment_deal(investment_deal_id)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_nested_investment_deal({ commit }, { investment_deal_id }) {
    return central_db
      .get_public_nested_investment_deal(investment_deal_id)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_deals({ commit }, { params }) {
    return central_db
      .get_investment_deals(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEALS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_nested_investment_deals({ commit }, { params }) {
    return central_db
      .get_nested_investment_deals(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEALS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_public_nested_investment_deals({ commit }, { params }) {
    return central_db
      .get_public_nested_investment_deals(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEALS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_fast_public_nested_investment_deals({ commit }, { params }) {
    return central_db
      .get_fast_public_nested_investment_deals(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEALS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_investment_deal({ commit }, payload) {
    return central_db
      .post_investment_deal(payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_nested_investment_deal({ commit }, payload) {
    return central_db
      .post_nested_investment_deal(payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_investment_deal({ commit }, { investment_deal_id, payload }) {
    return central_db
      .patch_investment_deal(investment_deal_id, payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_deal_team_member({ commit }, { investment_deal_team_member_id }) {
    return central_db
      .get_investment_deal_team_member(investment_deal_team_member_id)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL_TEAM_MEMBER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_deal_team_members({ commit }, { params }) {
    return central_db
      .get_investment_deal_team_members(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL_TEAM_MEMBERS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_investment_deal_team_member({ commit }, payload) {
    return central_db
      .post_investment_deal_team_member(payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL_TEAM_MEMBER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_nested_investment_deal_team_member({ commit }, payload) {
    return central_db
      .post_nested_investment_deal_team_member(payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL_TEAM_MEMBER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_investment_deal_team_member({ commit }, { investment_deal_team_member_id, payload }) {
    return central_db
      .patch_investment_deal_team_member(investment_deal_team_member_id, payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_DEAL_TEAM_MEMBER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_types({ commit }, { params }) {
    return central_db
      .get_investment_types(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_TYPES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_categories({ commit }, { params }) {
    return central_db
      .get_categories(params)
      .then(({data}) => {
        commit(SAVE_CATEGORIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_typical_investment_ticket_size_usds({ commit }, { params }) {
    return central_db
      .get_typical_investment_ticket_size_usds(params)
      .then(({data}) => {
        commit(SAVE_TYPICAL_INVESTMENT_TICKET_SIZE_USDS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_timelines({ commit }, { params }) {
    return central_db
      .get_investment_timelines(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_TIMELINES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_target_annual_gross_revenue_usds({ commit }, { params }) {
    return central_db
      .get_investment_target_annual_gross_revenue_usds(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_TARGET_ANNUAL_GROSS_REVENUE_USDS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_target_annual_ebitda_usds({ commit }, { params }) {
    return central_db
      .get_investment_target_annual_ebitda_usds(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_TARGET_ANNUAL_EBITDA_USDS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_target_number_of_employees({ commit }, { params }) {
    return central_db
      .get_investment_target_number_of_employees(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_TARGET_NUMBER_OF_EMPLOYEES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_ideal_return_percents({ commit }, { params }) {
    return central_db
      .get_ideal_return_percents(params)
      .then(({data}) => {
        commit(SAVE_IDEAL_RETURN_PERCENTS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_industries({ commit }, { params }) {
    return central_db
      .get_linkedin_industries(params)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_INDUSTRIES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  put_investment_deal_team_member_profile_picture({ commit }, { profile_picture, filename, content_type, investment_deal_team_member_id }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return central_db
      .put_investment_deal_team_member_profile_picture(profile_picture, filename, content_type, investment_deal_team_member_id)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  put_investment_deal_cover_photo({ commit }, { deal_cover_photo, filename, content_type, investment_deal_id }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return central_db
      .put_investment_deal_cover_photo(deal_cover_photo, filename, content_type, investment_deal_id)
      .then(({data}) => {
        commit(SAVE_DEAL_COVER_PHOTO_URL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  put_investment_deal_pitch_deck({ commit }, { pitch_deck, filename, content_type, investment_deal_id }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return central_db
      .put_investment_deal_pitch_deck(pitch_deck, filename, content_type, investment_deal_id)
      .then(({data}) => {
        commit(SAVE_PITCH_DECK_URL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  put_investment_deal_executive_summary({ commit }, { executive_summary, filename, content_type, investment_deal_id }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return central_db
      .put_investment_deal_executive_summary(executive_summary, filename, content_type, investment_deal_id)
      .then(({data}) => {
        commit(SAVE_EXECUTIVE_SUMMARY_URL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  put_investment_deal_due_diligence_package({ commit }, { due_diligence_package, filename, content_type, investment_deal_id }) {
    //for next request change headers temporary
    /*const interceptor = session.interceptors.request.use(config => {
      //won't need to set content-disposition since I include filename in url
      //config.headers.put['Content-Disposition'] = `form-data; name="file"; filename="${filename}"`
      config.headers.put['Content-Type'] = `${content_type}`
      return config
    })*/
    return central_db
      .put_investment_deal_due_diligence_package(due_diligence_package, filename, content_type, investment_deal_id)
      .then(({data}) => {
        commit(SAVE_DUE_DILIGENCE_PACKAGE_URL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
      /*.finally(() => {
        session.interceptors.request.eject(interceptor);
      })*/
  },
  post_email_list_lead({ commit }, payload) {
    return central_db
      .post_email_list_lead(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_make_investment({ commit }, payload) {
    return central_db
      .post_make_investment(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_available_portals_to_user({ commit }, { params }) {
    return central_db
      .get_available_portals_to_user(params)
      .then(({data}) => {
        commit(SAVE_AVAILABLE_PORTALS_TO_USER, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_user_emails_on_portal({ commit }, payload) {
    return central_db
      .post_user_emails_on_portal(payload)
      .then(({data}) => {
        commit(SAVE_USER_EMAILS_ON_PORTAL, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_linkedin_person_collect_url_request({ commit }, payload) {
    return central_db
      .post_linkedin_person_collect_url_request(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_demo_form({ commit }, payload) {
    return central_db
      .post_demo_form(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_brokerage_partnership_form({ commit }, payload) {
    return central_db
      .post_brokerage_partnership_form(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_person_profile({ commit }, { params }) {
    return central_db
      .get_person_profile(params)
      .then(({data}) => {
        commit(SAVE_PERSON_PROFILE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_organization_profile({ commit }, { params }) {
    return central_db
      .get_organization_profile(params)
      .then(({data}) => {
        commit(SAVE_ORGANIZATION_PROFILE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_delete_investment_deal({ commit }, payload) {
    return central_db
      .post_delete_investment_deal(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_investment_focuses({ commit }, payload) {
    return central_db
      .post_investment_focuses(payload)
      .then(({data}) => {
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
};
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_PERSON_INVESTORS] (state, person_investors) {
    state.person_investors = person_investors
    state.error = {}
  },
  [SAVE_PERSONS] (state, persons) {
    state.persons = persons
    state.error = {}
  },
  [SAVE_PERSON] (state, person) {
    state.person = person
    state.error = {}
  },
  [SAVE_TOTAL_PERSONS] (state, count) {
    state.total_persons = count
    state.error = {}
  },
  [SAVE_ORGANIZATIONS] (state, organizations) {
    state.organizations = organizations
    state.error = {}
  },
  [SAVE_ORGANIZATION_INVESTORS] (state, organization_investors) {
    state.organization_investors = organization_investors
    state.error = {}
  },
  [SAVE_CAMPAIGN_STATISTICS] (state, campaign_statistics) {
    state.campaign_statistics = campaign_statistics
    state.error = {}
  },
  [SAVE_DASHBOARD_STATISTICS] (state, dashboard_statistics) {
    state.dashboard_statistics = dashboard_statistics
    state.error = {}
  },
  [SAVE_EMAIL_STATISTICS] (state, email_statistics) {
    state.email_statistics = email_statistics
    state.error = {}
  },
  [SAVE_LINKEDIN_MESSAGE_STATISTICS] (state, linkedin_message_statistics) {
    state.linkedin_message_statistics = linkedin_message_statistics
    state.error = {}
  },
  [SAVE_CAMPAIGN_CHART] (state, campaign_chart) {
    state.campaign_chart = campaign_chart
    state.error = {}
  },
  [SAVE_DASHBOARD_CHART] (state, dashboard_chart) {
    state.dashboard_chart = dashboard_chart
    state.error = {}
  },
  [SAVE_EMAILS_SENT_CHART] (state, emails_sent_chart) {
    state.emails_sent_chart = emails_sent_chart
    state.error = {}
  },
  [SAVE_EMAIL_CHART] (state, email_chart) {
    state.email_chart = email_chart
    state.error = {}
  },
  [SAVE_LINKEDIN_MESSAGE_CHART] (state, linkedin_message_chart) {
    state.linkedin_message_chart = linkedin_message_chart
    state.error = {}
  },
  [SAVE_FILTER_QUERIES] (state, filter_queries) {
    state.filter_queries = filter_queries
    state.error = {}
  },
  [SAVE_FILTER_QUERY] (state, filter_query) {
    state.filter_query = filter_query
    state.error = {}
  },
  [SAVE_OR_QUERIES] (state, or_queries) {
    state.or_queries = or_queries
    state.error = {}
  },
  [SAVE_OR_QUERY] (state, or_query) {
    state.or_query = or_query
    state.error = {}
  },
  [SAVE_AND_QUERIES] (state, and_queries) {
    state.and_queries = and_queries
    state.error = {}
  },
  [SAVE_AND_QUERY] (state, and_query) {
    state.and_query = and_query
    state.error = {}
  },
  [SAVE_CREATE_DYNAMIC_LIST_FROM_FILTER] (state, data) {
    state.create_dynamic_list_from_filter = data
    state.error = {}
  },
  [SAVE_NUMBER_OF_RETRIEVES_CREATE_DYNAMIC_LIST_FROM_FILTER] (state, data) {
    state.number_of_retrieves_create_dynamic_list_from_filter = data
    state.error = {}
  },
  [SAVE_INVESTMENT_DEALS] (state, investment_deals) {
    state.investment_deals = investment_deals
    state.error = {}
  },
  [SAVE_INVESTMENT_DEAL] (state, investment_deal) {
    state.investment_deal = investment_deal
    state.error = {}
  },
  [SAVE_INVESTMENT_DEAL_TEAM_MEMBERS] (state, investment_deal_team_members) {
    state.investment_deal_team_members = investment_deal_team_members
    state.error = {}
  },
  [SAVE_INVESTMENT_DEAL_TEAM_MEMBER] (state, investment_deal_team_member) {
    state.investment_deal_team_member = investment_deal_team_member
    state.error = {}
  },
  [SAVE_INVESTMENT_TYPES] (state, investment_types) {
    state.investment_types = investment_types
    state.error = {}
  },
  [SAVE_CATEGORIES] (state, categories) {
    state.categories = categories
    state.error = {}
  },
  [SAVE_TYPICAL_INVESTMENT_TICKET_SIZE_USDS] (state, typical_investment_ticket_size_usds) {
    state.typical_investment_ticket_size_usds = typical_investment_ticket_size_usds
    state.error = {}
  },
  [SAVE_INVESTMENT_TIMELINES] (state, investment_timelines) {
    state.investment_timelines = investment_timelines
    state.error = {}
  },
  [SAVE_INVESTMENT_TARGET_ANNUAL_GROSS_REVENUE_USDS] (state, investment_target_annual_gross_revenue_usds) {
    state.investment_target_annual_gross_revenue_usds = investment_target_annual_gross_revenue_usds
    state.error = {}
  },
  [SAVE_INVESTMENT_TARGET_ANNUAL_EBITDA_USDS] (state, investment_target_annual_ebitda_usds) {
    state.investment_target_annual_ebitda_usds = investment_target_annual_ebitda_usds
    state.error = {}
  },
  [SAVE_INVESTMENT_TARGET_NUMBER_OF_EMPLOYEES] (state, investment_target_number_of_employees) {
    state.investment_target_number_of_employees = investment_target_number_of_employees
    state.error = {}
  },
  [SAVE_IDEAL_RETURN_PERCENTS] (state, ideal_return_percents) {
    state.ideal_return_percents = ideal_return_percents
    state.error = {}
  },
  [SAVE_LINKEDIN_INDUSTRIES] (state, linkedin_industries) {
    state.linkedin_industries = linkedin_industries
    state.error = {}
  },
  [SAVE_PERSON_INVESTORS_TOTAL_COUNT] (state, person_investors_total_count) {
    state.person_investors_total_count = person_investors_total_count
    state.error = {}
  },
  [SAVE_PERSONS_TOTAL_COUNT] (state, persons_total_count) {
    state.persons_total_count = persons_total_count
    state.error = {}
  },
  [SAVE_ORGANIZATIONS_TOTAL_COUNT] (state, organizations_total_count) {
    state.organizations_total_count = organizations_total_count
    state.error = {}
  },
  [SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT] (state, organization_investors_total_count) {
    state.organization_investors_total_count = organization_investors_total_count
    state.error = {}
  },
  [SAVE_LATEST_SEARCH_QUERY] (state, latest_search_query) {
    state.latest_search_query = latest_search_query
    state.error = {}
  },
  [SAVE_AVAILABLE_PORTALS_TO_USER] (state, user_emails_on_portal) {
    state.user_emails_on_portal = user_emails_on_portal
    state.error = {}
  },
  [SAVE_USER_EMAILS_ON_PORTAL] (state, available_portals_to_user) {
    state.available_portals_to_user = available_portals_to_user
    state.error = {}
  },
  [SAVE_PERSON_PROFILE] (state, person_profile) {
    state.person_profile = person_profile
    state.error = {}
  },
  [SAVE_ORGANIZATION_PROFILE] (state, organization_profile) {
    state.organization_profile = organization_profile
    state.error = {}
  },
  [SAVE_DEAL_COVER_PHOTO_URL] (state, deal_cover_photo_url) {
    state.deal_cover_photo_url = deal_cover_photo_url
    state.error = {}
  },
  [SAVE_PITCH_DECK_URL] (state, pitch_deck_url) {
    state.pitch_deck_url = pitch_deck_url
    state.error = {}
  },
  [SAVE_EXECUTIVE_SUMMARY_URL] (state, executive_summary_url) {
    state.executive_summary_url = executive_summary_url
    state.error = {}
  },
  [SAVE_DUE_DILIGENCE_PACKAGE_URL] (state, due_diligence_package_url) {
    state.due_diligence_package_url = due_diligence_package_url
    state.error = {}
  },
  [SAVE_CONTACTS] (state, contacts) {
    state.contacts = contacts
    state.error = {}
  },
  [SAVE_CONTACTS_TOTAL_COUNT] (state, contacts_total_count) {
    state.contacts_total_count = contacts_total_count
    state.error = {}
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};