// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules
export const SAVE_PERSON_INVESTORS = "SAVE_PERSON_INVESTORS"
export const SAVE_PERSONS = "SAVE_PERSONS"
export const SAVE_PERSON = "SAVE_PERSON"
export const SAVE_TOTAL_PERSONS = "SAVE_TOTAL_PERSONS"
export const SAVE_ORGANIZATIONS = "SAVE_ORGANIZATIONS"
export const SAVE_ORGANIZATION_INVESTORS = "SAVE_ORGANIZATION_INVESTORS"

export const SAVE_CAMPAIGN_STATISTICS = "SAVE_CAMPAIGN_STATISTICS"
export const SAVE_DASHBOARD_STATISTICS = "SAVE_DASHBOARD_STATISTICS"
export const SAVE_EMAIL_STATISTICS = "SAVE_EMAIL_STATISTICS"
export const SAVE_LINKEDIN_MESSAGE_STATISTICS = "SAVE_LINKEDIN_MESSAGE_STATISTICS"
export const SAVE_CAMPAIGN_CHART = "SAVE_CAMPAIGN_CHART"
export const SAVE_DASHBOARD_CHART = "SAVE_DASHBOARD_CHART"
export const SAVE_EMAILS_SENT_CHART = "SAVE_EMAILS_SENT_CHART"
export const SAVE_EMAIL_CHART = "SAVE_EMAIL_CHART"
export const SAVE_LINKEDIN_MESSAGE_CHART = "SAVE_LINKEDIN_MESSAGE_CHART"

export const SAVE_FILTER_QUERIES = "SAVE_FILTER_QUERIES"
export const SAVE_FILTER_QUERY = "SAVE_FILTER_QUERY"
export const SAVE_OR_QUERIES = "SAVE_OR_QUERIES"
export const SAVE_OR_QUERY = "SAVE_OR_QUERY"
export const SAVE_AND_QUERIES = "SAVE_AND_QUERIES"
export const SAVE_AND_QUERY = "SAVE_AND_QUERY"
export const SAVE_CREATE_DYNAMIC_LIST_FROM_FILTER = "SAVE_CREATE_DYNAMIC_LIST_FROM_FILTER"
export const SAVE_NUMBER_OF_RETRIEVES_CREATE_DYNAMIC_LIST_FROM_FILTER = "SAVE_NUMBER_OF_RETRIEVES_CREATE_DYNAMIC_LIST_FROM_FILTER"

export const SAVE_INVESTMENT_DEALS = "SAVE_INVESTMENT_DEALS"
export const SAVE_INVESTMENT_DEAL = "SAVE_INVESTMENT_DEAL"

export const SAVE_INVESTMENT_TYPES = "SAVE_INVESTMENT_TYPES"
export const SAVE_CATEGORIES = "SAVE_CATEGORIES"
export const SAVE_TYPICAL_INVESTMENT_TICKET_SIZE_USDS = "SAVE_TYPICAL_INVESTMENT_TICKET_SIZE_USDS"
export const SAVE_INVESTMENT_TIMELINES = "SAVE_INVESTMENT_TIMELINES"
export const SAVE_INVESTMENT_TARGET_ANNUAL_GROSS_REVENUE_USDS = "SAVE_INVESTMENT_TARGET_ANNUAL_GROSS_REVENUE_USDS"
export const SAVE_INVESTMENT_TARGET_ANNUAL_EBITDA_USDS = "SAVE_INVESTMENT_TARGET_ANNUAL_EBITDA_USDS"
export const SAVE_INVESTMENT_TARGET_NUMBER_OF_EMPLOYEES = "SAVE_INVESTMENT_TARGET_NUMBER_OF_EMPLOYEES"
export const SAVE_IDEAL_RETURN_PERCENTS = "SAVE_IDEAL_RETURN_PERCENTS"

export const SAVE_INVESTMENT_DEAL_TEAM_MEMBER = "SAVE_INVESTMENT_DEAL_TEAM_MEMBER"
export const SAVE_INVESTMENT_DEAL_TEAM_MEMBERS = "SAVE_INVESTMENT_DEAL_TEAM_MEMBERS"

export const SAVE_PERSON_INVESTORS_TOTAL_COUNT = "SAVE_PERSON_INVESTORS_TOTAL_COUNT"
export const SAVE_PERSONS_TOTAL_COUNT = "SAVE_PERSONS_TOTAL_COUNT"
export const SAVE_ORGANIZATIONS_TOTAL_COUNT = "SAVE_ORGANIZATIONS_TOTAL_COUNT"
export const SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT = "SAVE_ORGANIZATION_INVESTORS_TOTAL_COUNT"

export const SAVE_LATEST_SEARCH_QUERY = "SAVE_LATEST_SEARCH_QUERY"

export const SAVE_AVAILABLE_PORTALS_TO_USER = "SAVE_AVAILABLE_PORTALS_TO_USER"
export const SAVE_USER_EMAILS_ON_PORTAL = "SAVE_USER_EMAILS_ON_PORTAL"

export const SAVE_LINKEDIN_INDUSTRIES = "SAVE_LINKEDIN_INDUSTRIES"


export const SAVE_PERSON_PROFILE = "SAVE_PERSON_PROFILE"
export const SAVE_ORGANIZATION_PROFILE = "SAVE_ORGANIZATION_PROFILE"


export const SAVE_DEAL_COVER_PHOTO_URL = "SAVE_DEAL_COVER_PHOTO_URL"
export const SAVE_PITCH_DECK_URL = "SAVE_PITCH_DECK_URL"
export const SAVE_EXECUTIVE_SUMMARY_URL = "SAVE_EXECUTIVE_SUMMARY_URL"
export const SAVE_DUE_DILIGENCE_PACKAGE_URL = "SAVE_DUE_DILIGENCE_PACKAGE_URL"

export const SAVE_CONTACTS = "SAVE_CONTACTS"
export const SAVE_CONTACTS_TOTAL_COUNT = "SAVE_CONTACTS_TOTAL_COUNT"
