import clickOutside from '@/directives/click-ouside.js';
import { BSidebar, VBToggle } from 'bootstrap-vue';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('b-toggle', VBToggle);
    Vue.directive('b-sidebar', BSidebar);
  }
};

export default GlobalDirectives;
