import session from './session';

export default {
  get_outboxes(params) {
    return session.get('/api/outbox/outboxes/', { params: params });
  },
  get_outbox(outbox_id) {
    return session.get(`/api/outbox/outboxes/${outbox_id}/`);
  },
  post_outbox(data) {
    return session.post('/api/outbox/outboxes/', data);
  },
  patch_outbox(outbox_id, data) {
    return session.patch(`/api/outbox/outboxes/${outbox_id}/`, data);
  },
  delete_outbox(outbox_id) {
    return session.delete(`/api/outbox/outboxes/${outbox_id}/`);
  },
  get_email_templates(params) {
    return session.get('/api/outbox/emailtemplates/', { params: params });
  },
  get_email_template(email_template_id) {
    return session.get(`/api/outbox/emailtemplates/${email_template_id}/`);
  },
  delete_email_template(email_template_id) {
    return session.delete(`/api/outbox/emailtemplates/${email_template_id}/`);
  },
  post_email_template(data) {
    return session.post('/api/outbox/emailtemplates/', data);
  },
  patch_email_template(email_template_id, data) {
    return session.patch(`/api/outbox/emailtemplates/${email_template_id}/`, data);
  },
  post_test_smtp_connection(email, password, server, port) {
    return session.post('/api/outbox/test_smtp_connection/', { email, password, server, port })
  },
  get_latest_emails_sent(params) {
    return session.get('/api/outbox/get_latest_emails_sent/', { params: params });
  },
};