import auth from '@/api/auth';

import {
  PASSWORD_RESET_BEGIN,
  PASSWORD_RESET_CLEAR,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_EMAIL_BEGIN,
  PASSWORD_EMAIL_CLEAR,
  PASSWORD_EMAIL_FAILURE,
  PASSWORD_EMAIL_SUCCESS,
  PASSWORD_CHANGE_BEGIN,
  PASSWORD_CHANGE_CLEAR,
  PASSWORD_CHANGE_FAILURE,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CREATE_BEGIN,
  PASSWORD_CREATE_CLEAR,
  PASSWORD_CREATE_FAILURE,
  PASSWORD_CREATE_SUCCESS,
} from './types';

export default {
  namespaced: true,
  state: {
    emailCompleted: false,
    emailError: false,
    emailLoading: false,
    resetCompleted: false,
    resetError: false,
    resetLoading: false,
    changeCompleted: false,
    changeError: false,
    changeLoading: false,
    createCompleted: false,
    createError: false,
    createLoading: false,
  },
  actions: {
    resetPassword({ commit }, { uid, token, password1, password2 }) {
      commit(PASSWORD_RESET_BEGIN);
      return auth.resetAccountPassword(uid, token, password1, password2)
        .then(() => commit(PASSWORD_RESET_SUCCESS))
        .catch(() => commit(PASSWORD_RESET_FAILURE));
    },
    sendPasswordResetEmail({ commit }, { email }) {
      commit(PASSWORD_EMAIL_BEGIN);
      return auth.sendAccountPasswordResetEmail(email)
        .then(() => commit(PASSWORD_EMAIL_SUCCESS))
        .catch(() => commit(PASSWORD_EMAIL_FAILURE));
    },
    clearResetStatus({ commit }) {
      commit(PASSWORD_RESET_CLEAR);
    },
    clearEmailStatus({ commit }) {
      commit(PASSWORD_EMAIL_CLEAR);
    },
    changeAccountPassword({ commit }, { old_password, new_password1, new_password2 }) {
      commit(PASSWORD_CHANGE_BEGIN);
      return auth.changeAccountPassword(old_password, new_password1, new_password2)
        .then(() => commit(PASSWORD_CHANGE_SUCCESS))
        .catch(() => commit(PASSWORD_CHANGE_FAILURE));
    },
    clearChangeStatus({ commit }) {
      commit(PASSWORD_CHANGE_CLEAR);
    },
    createPassword({ commit }, { password1, password2 }) {
      commit(PASSWORD_CREATE_BEGIN);
      return auth.createAccountPassword(password1, password2)
        .then(() => commit(PASSWORD_CREATE_SUCCESS))
        .catch(() => commit(PASSWORD_CREATE_FAILURE));
    },
    clearCreateStatus({ commit }) {
      commit(PASSWORD_CREATE_CLEAR);
    },
  },
  mutations: {
    [PASSWORD_RESET_BEGIN](state) {
      state.resetLoading = true;
    },
    [PASSWORD_RESET_CLEAR](state) {
      state.resetCompleted = false;
      state.resetError = false;
      state.resetLoading = false;
    },
    [PASSWORD_RESET_FAILURE](state) {
      state.resetError = true;
      state.resetLoading = false;
    },
    [PASSWORD_RESET_SUCCESS](state) {
      state.resetCompleted = true;
      state.resetError = false;
      state.resetLoading = false;
    },
    [PASSWORD_EMAIL_BEGIN](state) {
      state.emailLoading = true;
    },
    [PASSWORD_EMAIL_CLEAR](state) {
      state.emailCompleted = false;
      state.emailError = false;
      state.emailLoading = false;
    },
    [PASSWORD_EMAIL_FAILURE](state) {
      state.emailError = true;
      state.emailLoading = false;
    },
    [PASSWORD_EMAIL_SUCCESS](state) {
      state.emailCompleted = true;
      state.emailError = false;
      state.emailLoading = false;
    },
    [PASSWORD_CHANGE_BEGIN](state) {
      state.changeLoading = true;
    },
    [PASSWORD_CHANGE_CLEAR](state) {
      state.changeCompleted = false;
      state.changeError = false;
      state.changeLoading = false;
    },
    [PASSWORD_CHANGE_FAILURE](state) {
      state.changeError = true;
      state.changeLoading = false;
    },
    [PASSWORD_CHANGE_SUCCESS](state) {
      state.changeCompleted = true;
      state.changeError = false;
      state.changeLoading = false;
    },
    [PASSWORD_CREATE_BEGIN](state) {
      state.createLoading = true;
    },
    [PASSWORD_CREATE_CLEAR](state) {
      state.createCompleted = false;
      state.createError = false;
      state.createLoading = false;
    },
    [PASSWORD_CREATE_FAILURE](state) {
      state.createError = true;
      state.createLoading = false;
    },
    [PASSWORD_CREATE_SUCCESS](state) {
      state.createCompleted = true;
      state.createError = false;
      state.createLoading = false;
    },
  },
};