const ProdPage = () => import(/* webpackChunkName: "dashboard" */ '../views/Layout/ProdPage.vue');

const InvestmentHubDashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '../views/Layout/ProdInvestmentHubDashboardLayout.vue');
const NavigatorDashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '../views/Layout/ProdNavigatorDashboardLayout.vue');
const AdvisoryDashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '../views/Layout/ProdAdvisoryDashboardLayout.vue');
const InternalTeamPortalDashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '../views/Layout/ProdInternalTeamPortalDashboardLayout.vue');
const AuthLayout = () => import(/* webpackChunkName: "auth" */ '@/views/Pages/ProdAuthLayout.vue');
const NoLayout = () => import(/* webpackChunkName: "no-layout" */ '@/views/Pages/ProdNoLayout.vue');
const NoLayoutLandingPages = () => import(/* webpackChunkName: "no-layout" */ '@/views/Pages/NoLayoutLandingPages.vue');

const NotFound = () => import(/* webpackChunkName: "global" */ '@/views/GeneralViews/ProdNotFoundPage.vue');

const Lists = () => import(/* webpackChunkName: "prospect-lists" */ '@/views/Production/ProdLists.vue');
const InvestmentDeals = () => import(/* webpackChunkName: "deals" */ '@/views/Production/ProdInvestmentDeals.vue');
const ProdInvestmentDealEdit = () => import(/* webpackChunkName: "deals" */ '@/views/Production/ProdInvestmentDealEdit.vue');
const ProdInvestmentDealView = () => import(/* webpackChunkName: "deals" */ '@/views/Production/ProdInvestmentDealView.vue');
const ProdListEdit = () => import(/* webpackChunkName: "prospect-lists" */ '@/views/Production/ProdListEdit.vue');
const ProdListView = () => import(/* webpackChunkName: "prospect-lists" */ '@/views/Production/ProdListView.vue');
const ProdNotifications = () => import(/* webpackChunkName: "notifications" */ '@/views/Production/ProdNotifications.vue');
const ProdRefer = () => import(/* webpackChunkName: "refer" */ '@/views/Production/ProdRefer.vue');
const ProdProfile = () => import(/* webpackChunkName: "social" */ '@/views/Production/ProdProfile.vue');
const ProdBilling = () => import(/* webpackChunkName: "billing" */ '@/views/Production/ProdBilling.vue');
const ProdSettings = () => import(/* webpackChunkName: "settings" */ '@/views/Production/ProdSettings.vue');
const ProdAgency = () => import(/* webpackChunkName: "agency" */ '@/views/Production/ProdAgency.vue');
const ProdSupport = () => import(/* webpackChunkName: "support" */ '@/views/Production/ProdSupport.vue');
const ProdMyPortals = () => import(/* webpackChunkName: "my-portals" */ '@/views/Production/ProdMyPortals.vue');

const Investors = () => import(/* webpackChunkName: "prospect" */ '@/views/Production/ProdInvestors.vue');
const People = () => import(/* webpackChunkName: "prospect" */ '@/views/Production/ProdPeople.vue');
const Companies = () => import(/* webpackChunkName: "prospect" */ '@/views/Production/ProdCompanies.vue');
const PeopleView = () => import(/* webpackChunkName: "prospect-people-view" */ '@/views/Production/ProdPeopleView.vue');
const CompaniesView = () => import(/* webpackChunkName: "prospect-companies-view" */ '@/views/Production/ProdCompaniesView.vue');


const ProdInvestmentHubMembership = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/ProdInvestmentHubMembership.vue');
const ProdInvestorPortal = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/ProdInvestorPortal.vue');
const ProdPartnershipPortal = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/ProdPartnershipPortal.vue');
const InternalTeamPortalDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/InternalTeamPortalDashboard.vue');
const AdvisoryDashboardResearch = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/AdvisoryDashboardResearch.vue');
const AdvisoryDashboardCapitalRaising = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/AdvisoryDashboardCapitalRaising.vue');
const AdvisoryDashboardInvestorIntroductions = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/AdvisoryDashboardInvestorIntroductions.vue');
const AdvisoryDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Production/AdvisoryDashboard.vue');


const ProdTrainingView = () => import(/* webpackChunkName: "training" */ '@/views/Production/ProdTrainingView.vue');
const ProdDatabaseTrainingView = () => import(/* webpackChunkName: "training" */ '@/views/Production/ProdDatabaseTrainingView.vue');
const ProdInvestmentAcceleratorTrialUpgradeView = () => import(/* webpackChunkName: "training" */ '@/views/Production/ProdInvestmentAcceleratorTrialUpgradeView.vue');
const ProdCopyGeneratorView = () => import(/* webpackChunkName: "training" */ '@/views/Production/ProdCopyGeneratorView.vue');
const ProdPrivatePlacementMemorandumView = () => import(/* webpackChunkName: "training" */ '@/views/Production/ProdPrivatePlacementMemorandumView.vue');

const Login = () => import(/* webpackChunkName: "auth" */ '@/views/Pages/ProdLogin.vue');
const Register = () => import(/* webpackChunkName: "auth" */ '@/views/Pages/ProdRegister.vue');
const Pricing = () => import(/* webpackChunkName: "pricing" */ '@/views/Pages/ProdPricing.vue');

const ProdAbout = () => import(/* webpackChunkName: "about" */ '@/views/Pages/ProdAbout.vue');
const ProdPublicInvestmentDeals = () => import(/* webpackChunkName: "deals" */ '@/views/Pages/ProdPublicInvestmentDeals.vue');
const ProdPublicInvestmentDealView = () => import(/* webpackChunkName: "deals" */ '@/views/Pages/ProdPublicInvestmentDealView.vue');
const ProdContact = () => import(/* webpackChunkName: "contact" */ '@/views/Pages/ProdContact.vue');
const ProdProspecting = () => import(/* webpackChunkName: "inactive" */ '@/views/Pages/ProdProspecting.vue');
const ProdCampaigns = () => import(/* webpackChunkName: "inactive" */ '@/views/Pages/ProdCampaigns.vue');
const ProdTracking = () => import(/* webpackChunkName: "inactive" */ '@/views/Pages/ProdTracking.vue');
const ProdSyncing = () => import(/* webpackChunkName: "inactive" */ '@/views/Pages/ProdSyncing.vue');
const ProdAgencyOption = () => import(/* webpackChunkName: "inactive" */ '@/views/Pages/ProdAgencyOption.vue');

const ProdSelectTrial = () => import(/* webpackChunkName: "billing" */ '@/views/Pages/ProdSelectTrial.vue');
const ProdBillingPurchase = () => import(/* webpackChunkName: "billing" */ '@/views/Pages/ProdBillingPurchase.vue');
const ProdOnboardingSignupSuccess = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingSignupSuccess.vue');
const ProdOnboardingStepOne = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepOne.vue');
const ProdOnboardingStepTwo = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepTwo.vue');
const ProdOnboardingStepThree = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepThree.vue');
const ProdOnboardingStepVideo = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepVideo.vue');
const ProdOnboardingStepInvestmentFocus = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepInvestmentFocus.vue');
const ProdOnboardingStepInvestmentDeal = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepInvestmentDeal.vue');
const ProdOnboardingStepBrokerFocus = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepBrokerFocus.vue');
const ProdOnboardingStepCalendar = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepCalendar.vue');
const ProdOnboardingStepCompleteInvestmentDeal = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepCompleteInvestmentDeal.vue');
const ProdOnboardingStepInvite = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepInvite.vue');
const ProdOnboardingStepConnectAccounts = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdOnboardingStepConnectAccounts.vue');
const ProdWelcomePortal = () => import(/* webpackChunkName: "onboarding" */ '@/views/Pages/ProdWelcomePortal.vue');

const InvestmentHubLandingLayout = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubLandingLayout.vue");
const InvestmentHubPlatformLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubPlatformLanding.vue");
const InvestmentHubNavigatorLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubNavigatorLanding.vue");
const InvestmentHubEventsLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubEventsLanding.vue");
const InvestmentHubCommunityLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubCommunityLanding.vue");
const InvestmentHubCaseStudiesLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdInvestmentHubCaseStudiesLanding.vue");
const InvestmentHubBlog = () => import(/* webpackChunkName: "landing" */ "@/views/Blog/ProdInvestmentHubBlog.vue");



//more auth from https://github.com/jakemcdermott/vue-django-rest-auth/blob/master/client/src/router/index.js
const PasswordReset = () => import(/* webpackChunkName: "auth" */ '../views/Pages/ProdPasswordReset');
const PasswordResetConfirm = () => import(/* webpackChunkName: "auth" */ '../views/Pages/ProdPasswordResetConfirm');
const VerifyEmail = () => import(/* webpackChunkName: "auth" */ '../views/Pages/ProdVerifyEmail');


//Malmberg Capital
const MalmbergCapitalLandingLayout = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalLandingLayout.vue");
const MalmbergCapitalAdvisoryLandingLayout = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalAdvisoryLandingLayout.vue");

const MalmbergCapitalHomeLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalHomeLanding.vue");
const MalmbergCapitalInvestmentsLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalInvestmentsLanding.vue");
const MalmbergCapitalCareersLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalCareersLanding.vue");
const MalmbergCapitalCapitalRaisingLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalCapitalRaisingLanding.vue");
const MalmbergCapitalCaseStudiesLanding = () => import(/* webpackChunkName: "landing" */ "@/views/ArgonDesignSystem/ProdMalmbergCapitalCaseStudiesLanding.vue");
const MalmbergCapitalInsights = () => import(/* webpackChunkName: "landing" */ "@/views/Blog/ProdMalmbergCapitalInsights.vue");

const NavigatorOfferPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/NavigatorOfferPage.vue');
const AdvisoryInvestorIntroductionsOfferPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/AdvisoryInvestorIntroductionsOfferPage.vue');
const AdvisoryCapitalRaisingOfferPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/AdvisoryCapitalRaisingOfferPage.vue');
const PaymentPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/PaymentPage.vue');
const AgreementPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/AgreementPage.vue');
const ConfirmationPage = () => import(/* webpackChunkName: "billing" */ '@/views/Products/ConfirmationPage.vue'); //we will use one only because of the URL tracking

/*
We can replace navigator with :productName
/investmenthub/products/navigator (window front page)
/investmenthub/lp/navigator (offering page)
/investmenthub/login/navigator (login page)
/investmenthub/billing/navigator (payment page)
/investmenthub/confirmation/navigator (confirmation)
/investmenthub/navigator (logged in)

Hub - blue
Navigator - gray
Community - blue
*/

import store from '../store';

//import api endpoints that are not called through Vuex because they don't affect state
import accounts from '@/api/accounts';
import campaign from '@/api/campaign';

import Vue from 'vue';

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
    .then(() => {
      if (!store.getters['auth/isAuthenticated']) {
        next({ name: 'Login' });
      } else {
        has_perm_for_portal_and_check_perm(to, next);
      }
    })
    .catch(() => {
      //if for example token has changed it will trigger loop so we shall log them out so they can try again
      next({ name: 'Logout' });
    })
};

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
    .then(() => {
      //because login url sometimes redirects, set traffic source here first for tracking
      if (to.query.source=='paid') {
        store.dispatch('accounts/set_cookie', {
          name: 'traffic_source',
          value: 'paid',
          domain: 'malmbergcapital.com',
          expires_in_hours: 1,
        })
      }
      //set funnel source
      if (to.query.funnel=='intro-video') {
        store.dispatch('accounts/set_cookie', {
          name: 'funnel',
          value: 'intro-video',
          domain: 'malmbergcapital.com',
          expires_in_hours: 1,
        })
      }
      const isAuthenticated = store.getters['auth/isAuthenticated'];
      const product = to.params.product;
  
      if (isAuthenticated) {
        store.dispatch('accounts/has_perm_for_portal_and_set_portal_session', { portal_id: null })
        .then(() => {
          let portal_id = store.getters['accounts/portal_id']
          store.dispatch('campaign/get_return_product_permissions', portal_id)
          .then(() => {
            if (product=='navigator') {
              var productName = 'Investment Hub Navigator'
            } else if (product=='advisory/investor-introductions') {
              var productName = 'Investor Introductions - Full Service'
            } else if (product=='advisory/capital-raising') {
              var productName = 'Capital Raising - Full Service'
            } else {
              var productName = 'Investment Hub Free'
            }
            const isCustomer = store.getters['campaign/return_product_permissions'].includes(productName)
  
            if (isCustomer) {
              // User is authenticated and is a customer
              next({
                name: 'ProdWelcomePortalProduct',
                query: to.query,
                params: { product: product }
              });
            } else {
              // User is authenticated but not a customer
              next(`/investmenthub/lp/${product}`);
            }
          })
        })
      } else {
        // User is not authenticated
        next();
      }
    });
};

const redirectLogout = (to, from, next) => {
  let loader = Vue.$loading.show()
  store.dispatch('auth/logout')
    .then(() => {
      loader.hide()
      next({ name: 'Login' });
    });
};

const requireLogout = (to, from, next) => {
  let loader = Vue.$loading.show()
  //possible that we could first check if authenticated before doing this
  //because when we try to logout someone that isnt signed in it will try to
  //delete session keys that are not there
  //now we ONLY delete/logout if authenticated already
  store.dispatch('auth/initialize')
    .then(() => {
      if (store.getters['auth/isAuthenticated']) {
        store.dispatch('auth/logout')
        .then(() => {
          loader.hide()
          next()
        });
      } else {
        loader.hide()
        next()
      }
    });
};

function has_perm_for_portal_and_check_perm (to, next) {
  let loader = Vue.$loading.show()
  let portal_id = to.params.portal_id
  //now leaving out portal_id create some kind of loop here that we need to fix
  //exception for ProdProfileSlug because I do not want to check has perm for each profile
  if (!(portal_id==store.getters['accounts/portal_id'])) {
    if (store.getters['accounts/portal_id']=='not_set' || (to.name!='ProdProfileSlug' && to.name!='Feed')) {
        store.dispatch('accounts/has_perm_for_portal_and_set_portal_session', { portal_id })
        .then(() => {
          if (store.getters['accounts/logout']==true) {
            //this is used in prodRoutes, if api key is incorrect it will trigger loop because this
            //return non 200 response because no access; hence we need person to reauthentificate, so logout
            store.commit('accounts/SAVE_LOGOUT', false)
            store.commit('auth/REMOVE_TOKEN')
            next({ name: 'Login' });
          }
          loader.hide()
          if (!store.getters['accounts/has_perm_for_portal']) {
            next({ name: 'My Portals' });
          }
          else if (store.getters['accounts/no_portal_id_set']) {
            if (to.name=='ProdProfileSlug' || to.name=='Feed') {
              check_permission_and_proceed(to, next, true);
            } else {
              let params = to.params
              params.portal_id = store.getters['accounts/portal_id']
              next({ name: to.name, params: params });
            }
          } else {
            check_permission_and_proceed(to, next, true);
          }
      })
    } else {
      //if feed or profile where check perm not relevant - then check perm false!
      if (store.getters['accounts/logout']==true) {
        //this is used in prodRoutes, if api key is incorrect it will trigger loop because this
        //return non 200 response because no access; hence we need person to reauthentificate, so logout
        store.commit('accounts/SAVE_LOGOUT', false)
        store.commit('auth/REMOVE_TOKEN')
        next({ name: 'Login' });
      }
      loader.hide()
      if (!store.getters['accounts/has_perm_for_portal']) {
        next({ name: 'My Portals' });
      }
      else if (store.getters['accounts/no_portal_id_set']) {
        //set no_portal_id_set as false to avoid loop
        store.commit('accounts/SAVE_NO_PORTAL_ID_SET', false)
        check_permission_and_proceed(to, next, false);
      } else {
        check_permission_and_proceed(to, next, false);
      }
    }
  } else {
      loader.hide()
      if (!store.getters['accounts/has_perm_for_portal']) {
        next({ name: 'My Portals' });
      }
      else if (store.getters['accounts/no_portal_id_set']) {
        //set no_portal_id_set as false to avoid loop
        store.commit('accounts/SAVE_NO_PORTAL_ID_SET', false)
        check_permission_and_proceed(to, next, true);
      } else {
        check_permission_and_proceed(to, next, false);
      }
  }
};

function check_permission_and_proceed (to, next, do_new_check) {
  let loader = Vue.$loading.show()
  
  //check object permission on certain views/routes
  switch( to.name ){
    case 'List View':
      store.dispatch('accounts/post_check_has_object_permission', {
        portal_id: to.params.portal_id,
        list_id: to.params.list_id,
      })
      .then(() => {
        if (store.getters['accounts/has_object_permission']==false) {
          loader.hide()
          let url=`/investmenthub/${store.getters['accounts/portal_id']}/lists`
          next(url);
        }
      })
      break;
    case 'List Edit':
      store.dispatch('accounts/post_check_has_object_permission', {
        portal_id: to.params.portal_id,
        list_id: to.params.list_id,
      })
      .then(() => {
        if (store.getters['accounts/has_object_permission']==false) {
          loader.hide()
          let url=`/investmenthub/${store.getters['accounts/portal_id']}/lists`
          next(url);
        }
      })
      break;
    case 'Investment Deal Edit':
      store.dispatch('accounts/post_check_has_object_permission', {
        portal_id: to.params.portal_id,
        investment_deal_id: to.params.investment_deal_id,
      })
      .then(() => {
        if (store.getters['accounts/has_object_permission']==false) {
          loader.hide()
          let url=`/investmenthub/${store.getters['accounts/portal_id']}/investment-deals`
          next(url);
        }
      })
      break;
  }
  //we must set portal id since not all view inlcude params.portal_id
  var portal_id = store.getters['accounts/portal_id']
  //inv acc check
  if (do_new_check) {
    store.dispatch('campaign/get_return_product_permissions', portal_id)
    store.dispatch('campaign/get_is_approved_qualified_investor', portal_id)
  }
  //inv nav check
  if (to.meta.permission.includes('can_use_investment_hub')) {
    if (do_new_check) {
      store.dispatch('campaign/get_return_product_permissions', portal_id)
      .then(() => {
        loader.hide()
        switch( to.meta.permission ){
          case 'can_use_investment_hub_free':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Free') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Starter') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'My Portals' });
            }
            break;
            case 'can_use_investment_hub_starter':
              if (
                store.getters['campaign/return_product_permissions'].includes('Investment Hub Starter') ||
                store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
                store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
                store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
              ) {
                check_onboarding_status(to, next);
              } else {
                next({ name: 'Pricing' });
              }
              break;
              case 'can_use_investment_hub_business':
                if (
                  store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
                  store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
                  store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
                ) {
                  check_onboarding_status(to, next);
                } else {
                  next({ name: 'Pricing' });
                }
                break;
          /* basic is removed and renamed
          case 'can_use_investment_hub_basic':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Basic') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'Pricing' });
            }
            break;*/
          case 'can_use_investment_hub_navigator':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'Pricing' });
            }
            break;
          case 'can_use_investment_hub_enterprise':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'Pricing' });
            }
            break;
        }
      })
    } else {
      loader.hide()
      switch( to.meta.permission ){
        case 'can_use_investment_hub_free':
          if (
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Free') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Starter') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
          ) {
            check_onboarding_status(to, next);
          } else {
            next({ name: 'My Portals' });
          }
          break;
        case 'can_use_investment_hub_starter':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Starter') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'Pricing' });
            }
            break;
        case 'can_use_investment_hub_business':
            if (
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Business') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
              store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
            ) {
              check_onboarding_status(to, next);
            } else {
              next({ name: 'Pricing' });
            }
            break;
        /* basic is being removed
        case 'can_use_investment_hub_basic':
          if (
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Basic') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
          ) {
            check_onboarding_status(to, next);
          } else {
            next({ name: 'Pricing' });
          }
          break;*/
        case 'can_use_investment_hub_navigator':
          if (
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Navigator') ||
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
          ) {
            check_onboarding_status(to, next);
          } else {
            next({ name: 'Pricing' });
          }
          break;
        case 'can_use_investment_hub_enterprise':
          if (
            store.getters['campaign/return_product_permissions'].includes('Investment Hub Enterprise')
          ) {
            check_onboarding_status(to, next);
          } else {
            next({ name: 'Pricing' });
          }
          break;
      }
    }
  } else {
    loader.hide()
    switch( to.meta.permission ){
      case 'all_permissions':
        //almost won't use becase can_use_investment_hub_free covers all permissions and we need to have that one atleast so it check inv nav perm
        check_onboarding_status(to, next);
        break;
    }
  }
};

function check_onboarding_status (to, next) {
  let loader = Vue.$loading.show()
  //console.log(store.getters['accounts/onboarding_status'])
  if (!store.getters['accounts/onboarding_status']) {
    store.dispatch('accounts/get_onboarding_status')
    .then(() => {
      loader.hide()
      if (store.getters['accounts/onboarding_status']=='signup_success') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/signup-success`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='set_password') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-one`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='join_community') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-two`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='personalize_experience') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-three`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='investment_focus') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-investment-focus`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='investment_deal') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-investment-deal`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='refer') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-invite`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else if (store.getters['accounts/onboarding_status']=='connect_accounts') {
        let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-connect-accounts`
        if (to.path.includes('billing')) {
          next();
        } else if (!(url==to.path)) {
          next(url);
        } else {
          next();
        }
      } else {
        next();
      }
    })
  } else {
    loader.hide()
    if (store.getters['accounts/onboarding_status']=='signup_success') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/signup-success`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='set_password') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-one`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='join_community') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-two`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='personalize_experience') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-three`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='investment_focus') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-investment-focus`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='investment_deal') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-investment-deal`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='refer') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-invite`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else if (store.getters['accounts/onboarding_status']=='connect_accounts') {
      let url=`/investmenthub/${store.getters['accounts/portal_id']}/welcome/step-connect-accounts`
      if (to.path.includes('billing')) {
        next();
      } else if (!(url==to.path)) {
        next(url);
      } else {
        next();
      }
    } else {
      next();
    }
  }
}

let authPages = {
  path: '/investmenthub/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login,
	    beforeEnter: requireUnauthenticated,
    },
    {
      path: 'login/:product(.*)',
      name: 'LoginProduct',
      component: Login,
	    beforeEnter: requireUnauthenticated,
    },
    {
      path: 'logout',
      name: 'Logout',
      beforeEnter: redirectLogout,
    },
    {
      path: 'register',
      name: 'Register',
      component: Register,
      beforeEnter: requireLogout,
    },
    {
      path: 'pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: 'about',
      name: 'ProdAbout',
      component: ProdAbout
    },
    {
      path: 'opportunities',
      name: 'ProdPublicInvestmentDeals',
      component: ProdPublicInvestmentDeals
    },
    {
      path: 'opportunities/:investment_deal_id(\\d+)',
      name: 'ProdPublicInvestmentDealView',
      component: ProdPublicInvestmentDealView
    },
    {
      path: 'contact',
      name: 'ProdContact',
      component: ProdContact
    },
    {
      path: 'prospecting',
      name: 'ProdProspecting',
      component: ProdProspecting
    },
    {
      path: 'campaigns',
      name: 'ProdCampaigns',
      component: ProdCampaigns
    },
    {
      path: 'tracking',
      name: 'ProdTracking',
      component: ProdTracking
    },
    {
      path: 'syncing',
      name: 'ProdSyncing',
      component: ProdSyncing
    },
    {
      path: 'agency-option',
      name: 'ProdAgencyOption',
      component: ProdAgencyOption
    },
    {
      path: 'password_reset',
      name: 'PasswordReset',
      component: PasswordReset,
    },
    {
      path: 'password_reset/:uid/:token',
      component: PasswordResetConfirm,
    },
    {
      path: 'register/:key',
      component: VerifyEmail,
    },
    { path: '*', component: NotFound }
  ]
};
let prospectMenu = {
  path: '/investmenthub/:portal_id(\\d+)?/navigator/prospect/',
  component: NavigatorDashboardLayout,
  name: 'Prospect',
  redirect: '/investmenthub/navigator/prospect/investors',
  children: [
    {
      path: 'investors',
      name: 'Investors',
      component: Investors,
	    beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'people',
      name: 'People',
      component: People,
	    beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'companies',
      name: 'Companies',
      component: Companies,
	    beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'people/:person_id(\\d+)',
      name: 'PeopleView',
      component: PeopleView,
	    beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'companies/:organization_id(\\d+)',
      name: 'CompaniesView',
      component: CompaniesView,
	    beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    }
  ]
};

let navigatorTrainingMenu = {
  path: '/investmenthub/:portal_id(\\d+)?/navigator/',
  name: 'Navigator Training',
  redirect: '/investmenthub/navigator/training/53/welcome',
  component: NavigatorDashboardLayout,
  children: [
  {
    path: 'support',
    name: 'Support',
    component: ProdSupport,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'training/:training_id(\\d+)',
    name: 'Training View',
    component: ProdTrainingView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'training/:training_module_id(\\d+)/:slug',
    name: 'Database Training View',
    component: ProdDatabaseTrainingView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investment-accelerator/trial-upgrade',
    name: 'Investment Accelerator Trial Upgrade View',
    component: ProdInvestmentAcceleratorTrialUpgradeView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investment-accelerator/copy-generator',
    name: 'Investment Accelerator Copy Generator View',
    component: ProdCopyGeneratorView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investment-accelerator/ppm/:ppm_type',
    name: 'Investment Accelerator Private Placement Memorandum View',
    component: ProdPrivatePlacementMemorandumView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  }
  ]
}

let investmenthubMenu = {
  path: '/investmenthub/:portal_id(\\d+)?/',
  name: 'investmenthub',
  redirect: '/investmenthub/investment-opportunities',
  component: InvestmentHubDashboardLayout,
  children: [
  {
    path: 'investment-opportunities',
    name: 'Investment Deals',
    component: InvestmentDeals,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investment-opportunities/view/:investment_deal_id(\\d+)',
    name: 'Investment Deal View',
    component: ProdInvestmentDealView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
      path: 'investment-opportunities/edit/:investment_deal_id(\\d+)',
      name: 'Investment Deal Edit',
      component: ProdInvestmentDealEdit,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
  },
  {
    path: 'investment-opportunities/new',
    name: 'Investment Deal New',
    component: ProdInvestmentDealEdit,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'membership',
    name: 'Investment Hub Membership',
    component: ProdInvestmentHubMembership,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investor-portal',
    name: 'Investor Portal',
    component: ProdInvestorPortal,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'partnership-portal',
    name: 'Partnership Portal',
    component: ProdPartnershipPortal,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  }
  ]
}

let navigatorMenu = {
  path: '/investmenthub/:portal_id(\\d+)?/navigator/',
  name: 'home',
  redirect: '/investmenthub/navigator/dashboard',
  component: NavigatorDashboardLayout,
  children: [
    {
      path: 'dashboard',
      name: 'dashboard',
      components: { default: ProdPage },
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
  {
    path: 'lists',
    name: 'Lists',
    component: Lists,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'lists/view/:list_id(\\d+)',
    name: 'List View',
    component: ProdListView,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_navigator'
    }
  },
  {
      path: 'lists/edit/:list_id(\\d+)',
      name: 'List Edit',
      component: ProdListEdit,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_navigator'
      }
  },
  {
    path: 'lists/new',
    name: 'List New',
    component: ProdListEdit,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_navigator'
    }
  },
  {
    path: 'notifications',
    name: 'Notifications',
    component: ProdNotifications,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'refer',
    name: 'Refer',
    component: ProdRefer,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'profile',
    name: 'Profile',
    component: ProdProfile,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'billing',
    name: 'Billing',
    component: ProdBilling,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'settings',
    name: 'Settings',
    component: ProdSettings,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'agency',
    name: 'Agency',
    component: ProdAgency,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'my-portals',
    name: 'My Portals',
    component: ProdMyPortals,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  }
  ]
}


let advisoryMenu = {
  path: '/investmenthub/:portal_id(\\d+)?/advisory/',
  name: 'Advisory Layout',
  redirect: '/investmenthub/advisory/dashboard',
  component: AdvisoryDashboardLayout,
  children: [
  {
    path: 'dashboard',
    name: 'Advisory Dashboard',
    component: AdvisoryDashboard,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'investor-introductions',
    name: 'Advisory Dashboard Investor Introductions',
    component: AdvisoryDashboardInvestorIntroductions,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'capital-raising',
    name: 'Advisory Dashboard Capital Raising',
    component: AdvisoryDashboardCapitalRaising,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  },
  {
    path: 'research',
    name: 'Advisory Dashboard Research',
    component: AdvisoryDashboardResearch,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  }
  ]
}
let internalTeamPortal = {
  path: '/investmenthub/:portal_id(\\d+)?/corporate/',
  name: 'Internal Team Portal',
  redirect: '/investmenthub/corporate/dashboard',
  component: InternalTeamPortalDashboardLayout,
  children: [
  {
    path: 'dashboard',
    name: 'Internal Team Portal Dashboard',
    component: InternalTeamPortalDashboard,
    beforeEnter: requireAuthenticated,
    meta: {
      permission: 'can_use_investment_hub_free'
    }
  }
  ]
}


let landingPagesInvestmentHub = {
  path: '/investmenthub/',
  component: InvestmentHubLandingLayout,
  name: 'InvestmentHubLandingLayout',
  children: [
    {
      path: '/',
      name: 'InvestmentHubPlatformLanding',
      component: InvestmentHubPlatformLanding
    },
    {
      path: 'products/navigator',
      name: 'InvestmentHubNavigatorLanding',
      component: InvestmentHubNavigatorLanding
    },
    {
      path: 'events',
      name: 'InvestmentHubEventsLanding',
      component: InvestmentHubEventsLanding
    },
    {
      path: 'community',
      name: 'InvestmentHubCommunityLanding',
      component: InvestmentHubCommunityLanding
    },
    {
      path: 'case-studies',
      name: 'InvestmentHubCaseStudiesLanding',
      component: InvestmentHubCaseStudiesLanding
    },
    {
      path: 'blog',
      name: 'InvestmentHubBlog',
      component: InvestmentHubBlog
    }
  ]
};


let landingPagesMalmbergCapital = {
  path: '/',
  component: MalmbergCapitalLandingLayout,
  name: 'MalmbergCapitalLandingLayout',
  children: [
    {
      path: '/',
      name: 'MalmbergCapitalHomeLanding',
      component: MalmbergCapitalHomeLanding
    },
    {
      path: 'investments',
      name: 'MalmbergCapitalInvestmentsLanding',
      component: MalmbergCapitalInvestmentsLanding
    },
    {
      path: 'insights',
      name: 'MalmbergCapitalInsights',
      component: MalmbergCapitalInsights
    },
    {
      path: 'careers',
      name: 'MalmbergCapitalCareersLanding',
      component: MalmbergCapitalCareersLanding
    }
  ]
};

let landingPagesMalmbergCapitalAdvisory = {
  path: '/',
  component: MalmbergCapitalAdvisoryLandingLayout,
  name: 'MalmbergCapitalAdvisoryLandingLayout',
  children: [
    {
      path: 'advisory/capitalraising',
      name: 'MalmbergCapitalCapitalRaisingLanding',
      component: MalmbergCapitalCapitalRaisingLanding
    },
    {
      path: 'advisory/case-studies',
      name: 'MalmbergCapitalCaseStudiesLanding',
      component: MalmbergCapitalCaseStudiesLanding
    }
  ]
};

let noLayoutPages = {
  path: '/investmenthub/:portal_id(\\d+)?/',
  component: NoLayout,
  name: 'NoLayout',
  children: [
    {
      path: 'agreement/:product(.*)',
      name: 'AgreementPage',
      component: AgreementPage,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'billing/:product(.*)',
      name: 'PaymentPage',
      component: PaymentPage,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'confirmation/:product(.*)',
      name: 'ConfirmationPage',
      component: ConfirmationPage,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    /*{
      path: 'billing/trial',
      name: 'ProdSelectTrial',
      component: ProdSelectTrial,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },*/
    {
      path: 'welcome-portal',
      name: 'ProdWelcomePortal',
      component: ProdWelcomePortal,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome-portal/:product(.*)',
      name: 'ProdWelcomePortalProduct',
      component: ProdWelcomePortal,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    /*{
      path: 'billing/purchase',
      name: 'ProdBillingPurchase',
      component: ProdBillingPurchase,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },*/
    {
      path: 'welcome/signup-success',
      name: 'ProdOnboardingSignupSuccess',
      component: ProdOnboardingSignupSuccess,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-one',
      name: 'ProdOnboardingStepOne',
      component: ProdOnboardingStepOne,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-two',
      name: 'ProdOnboardingStepTwo',
      component: ProdOnboardingStepTwo,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-three',
      name: 'ProdOnboardingStepThree',
      component: ProdOnboardingStepThree,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-video',
      name: 'ProdOnboardingStepVideo',
      component: ProdOnboardingStepVideo,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-investment-focus',
      name: 'ProdOnboardingStepInvestmentFocus',
      component: ProdOnboardingStepInvestmentFocus,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-investment-deal',
      name: 'ProdOnboardingStepInvestmentDeal',
      component: ProdOnboardingStepInvestmentDeal,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-complete-investment-deal',
      name: 'ProdOnboardingStepCompleteInvestmentDeal',
      component: ProdOnboardingStepCompleteInvestmentDeal,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-broker-focus',
      name: 'ProdOnboardingStepBrokerFocus',
      component: ProdOnboardingStepBrokerFocus,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-calendar',
      name: 'ProdOnboardingStepCalendar',
      component: ProdOnboardingStepCalendar,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-invite',
      name: 'ProdOnboardingStepInvite',
      component: ProdOnboardingStepInvite,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    },
    {
      path: 'welcome/step-connect-accounts',
      name: 'ProdOnboardingStepConnectAccounts',
      component: ProdOnboardingStepConnectAccounts,
      beforeEnter: requireAuthenticated,
      meta: {
        permission: 'can_use_investment_hub_free'
      }
    }
  ]
};

let noLayoutLandingPages = {
  path: '/investmenthub/',
  component: NoLayoutLandingPages,
  name: 'NoLayoutLandingPages',
  children: [
    {
      path: 'lp/navigator',
      name: 'NavigatorOfferPage',
      component: NavigatorOfferPage,
      meta: { navbarClass: 'bg-gradient-dark' }
    },
    {
      path: 'lp/advisory/investor-introductions',
      name: 'AdvisoryInvestorIntroductionsOfferPage',
      component: AdvisoryInvestorIntroductionsOfferPage,
      meta: { navbarClass: 'bg-gradient-dark' }
    },
    {
      path: 'lp/advisory/capital-raising',
      name: 'AdvisoryCapitalRaisingOfferPage',
      component: AdvisoryCapitalRaisingOfferPage,
      meta: { navbarClass: 'bg-gradient-dark' }
    }
  ]
};

const routes = [
  landingPagesMalmbergCapital,
  landingPagesMalmbergCapitalAdvisory,
  landingPagesInvestmentHub,
  {
    path: '/investmenthub/navigator/',
    component: NavigatorDashboardLayout,
    name: 'feed',
    children: [
      {
        path: 'person/:profile_slug',
        name: 'ProdProfileSlug',
        component: ProdProfile,
        beforeEnter: requireAuthenticated,
        meta: {
          permission: 'can_use_investment_hub_free'
        }
      },
    ]
  },
  navigatorMenu,
  investmenthubMenu,
  navigatorTrainingMenu,
	prospectMenu,
  advisoryMenu,
  internalTeamPortal,
  noLayoutPages,
  noLayoutLandingPages,
	authPages
];

export default routes;
