import session from './session';

export default {
  login(email, password) {
    return session.post('/rest-auth/login/', { email, password });
  },
  post_login_linkedin(data) {
    return session.post('/api/rest-auth/linkedin/', data)
  },
  post_login_facebook(data) {
    return session.post('/api/rest-auth/facebook/', data);
  },
  logout() {
    //this one actually delete token object in db so it recreates upon login for security
    return session.post('/rest-auth/logout/', {});
  },
  post_create_free_account(email, first_name, last_name) {
    return session.post('/api/create_free_account/', { email, first_name, last_name });
  },
  //the original createAccount from rest-auth, won't use 
  //since we do not use username or provide password
  /*createAccount(username, password1, password2, email) {
    return session.post('/rest-auth/registration/', { username, password1, password2, email });
  },*/
  changeAccountPassword(old_password, new_password1, new_password2) {
    return session.post('/rest-auth/password/change/', { old_password, new_password1, new_password2 });
  },
  sendAccountPasswordResetEmail(email) {
    //uid and token are sent in email after calling /rest-auth/password/reset/ (to then be used in resetAccountPassword I suppose)
    return session.post('/rest-auth/password/reset/', { email });
  },
  resetAccountPassword(uid, token, new_password1, new_password2) { // eslint-disable-line camelcase
    //dependent on sendAccountPasswordResetEmail
    return session.post('/rest-auth/password/reset/confirm/', { uid, token, new_password1, new_password2 });
  },
  getAccountDetails() {
    return session.get('/rest-auth/user/');
  },
  updateAccountDetails(data) {
    return session.patch('/rest-auth/user/', data);
  },
  verifyAccountEmail(key) {
    return session.post('/rest-auth/registration/verify-email/', { key });
  },
  createAccountPassword(password1, password2) { 
    return session.post('/api/rest-auth/password/create/', { password1, password2 });
  },
};