import session from './session';

export default {
  set_session(key, value) {
    return session.post('/api/session/', { key, value });
  },
  get_session(key) {
    return session.get('/api/session/', { params: {key} });
  },
  delete_session(key) {
    return session.delete(`/api/session/?key=${key}`);
  },
  set_cookie(name, value, domain) {
    return session.post('/api/cookie/', { name, value, domain });
  },
  get_cookie(name) {
    return session.get('/api/cookie/', { params: {name} });
  },
  has_perm(permission, app, model, object_id) {
    return session.get('/api/has_perm/', { params: {permission, app, model, object_id} });
  },
  has_perm_for_portal_and_set_portal_session(portal_id) {
    return session.post('/api/has_perm_for_portal_and_set_portal_session/', { portal_id });
  },
  get_onboarding_status() {
    return session.get('/api/onboarding_status/')
  },
  post_onboarding_status(data) {
    return session.post('/api/onboarding_status/', data)
  },
  get_current_user_portal_settings(portal_id) {
    return session.get('/api/get_current_user_portal_settings/', { params: {portal_id} });
  },
  get_user(user_id) {
    return session.get(`/api/users/${user_id}/`);
  },
  patch_user(user_id, data) {
    return session.patch(`/api/users/${user_id}/`, data);
  },
  get_normal_user(user_id) {
    return session.get(`/api/normal/users/${user_id}/`);
  },
  patch_normal_user(user_id, data) {
    return session.patch(`/api/normal/users/${user_id}/`, data);
  },
  put_user_profile_picture(profile_picture, filename, content_type) {
    return session.put(`/api/upload/${filename}`, profile_picture, {
      'Authorization': session.defaults.headers.Authorization,
      'Content-Type': content_type,
    });
  },
  post_connect_facebook(data) {
    return session.post('/api/rest-auth/facebook/connect/', data);
  },
  post_connect_microsoft(data) {
    return session.post('/api/rest-auth/microsoft/connect/', data);
  },
  post_connect_google(data) {
    return session.post('/api/rest-auth/google/connect/', data);
  },
  post_connect_hubspot(data) {
    return session.post('/api/rest-auth/hubspot/connect/', data);
  },
  post_connect_linkedin(data) {
    return session.post('/api/rest-auth/linkedin/connect/', data)
  },
  get_linkedin_oauth_credentials() {
    //I send along secret code because endpoint is open without token
    return session.get('/api/rest-auth/linkedin/credentials/', {params: {secret_code: 'lskjdflkj32l8yh2ii3uhsdjjfkj3'}});
  },
  get_microsoft_oauth_credentials() {
    //I send along secret code because endpoint is open without token
    return session.get('/api/rest-auth/microsoft/credentials/', {params: {secret_code: 'lskjdflkj32l8yh2ii3uhsdjjfkj3'}});
  },
  get_linkedin_access_token() {
    return session.get('/api/rest-auth/linkedin/access_token/');
  },
  get_linkedin_member() {
    return session.get('/api/rest-auth/linkedin/member/');
  },
  //this is added to extra data so no need to retrieve
  /*get_linkedin_member_profile() {
    return session.get('https://api.linkedin.com/v2/me');
  },
  get_linkedin_member_profile_picture() {
    return session.get('https://api.linkedin.com/v2/me', { params: {
      projection: '(id,firstName,lastName,profilePicture(displayImage~:playableStreams))',
    }});
  },
  get_linkedin_member_email() {
    return session.get('https://api.linkedin.com/v2/emailAddress', { params: {
      q: 'members',
      projection: '(elements*(handle~))',
    }});
  },*/
  get_google_oauth_credentials() {
    //I send along secret code because endpoint is open without token
    return session.get('/api/rest-auth/google/credentials/');
  },
  get_social_account(social_account_id) {
    return session.get(`/api/socialaccounts/${social_account_id}/`);
  },
  get_social_accounts() {
    //gets social accounts for logged in user
    return session.get('/api/socialaccounts/');
  },
  post_disconnect_social_account(social_account_id) {
    return session.post(`/api/socialaccounts/${social_account_id}/disconnect/`);
  },
  get_agency_portals() {
    return session.get('/api/get_agency_portals/');
  },
  get_my_portals() {
    return session.get('/api/get_my_portals/');
  },
  get_hubspot_oauth_credentials() {
    return session.get('/api/rest-auth/hubspot/credentials/');
  },
  get_hubspot_access_token(portal_id) {
    return session.get('/api/rest-auth/hubspot/access_token/', {params: {portal_id: portal_id}});
  },
  get_hubspot_users(portal_id) {
    return session.get('/api/rest-auth/hubspot/users/', {params: {portal_id: portal_id}});
  },
  get_email_available(email) {
    return session.get('/api/email_available/', {params: {email: email}});
  },
  get_user_registered_before() {
    return session.get('/api/user_registered_before/');
  },
  post_create_free_account_related_objects(company, traffic_source, funnel) {
    return session.post('/api/create_free_account_related_objects/', { company, traffic_source, funnel });
  },
  post_check_has_object_permission(data) {
    return session.post('/api/check_has_object_permission/', data);
  },
  log_error_cloudwatch(value) {
    return session.post('/api/log_error_cloudwatch/', { value });
  },
  add_to_mautic_segment(email, segment_id) {
    return session.post('/api/add_to_mautic_segment/', { email, segment_id });
  },
  add_mautic_tags(email, list_of_tags) {
    return session.post('/api/add_mautic_tags/', { email, list_of_tags });
  },
  set_first_purchase_discount_end_time(email) {
    return session.post('/api/set_first_purchase_discount_end_time/', { email });
  },
  get_user_profile_slug(user_id) {
    return session.get('/api/user_profile_slug/', { params: {user_id} });
  },
  get_flatfile_key() {
    return session.get('/api/flatfile_key/');
  },
};