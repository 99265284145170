import auth from '@/api/auth';
import session from '@/api/session';
import store from '@/store';

import {
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_TOKEN,
  SET_EMAIL_ACCOUNT_EXISTS,
  SET_PASSWORD_INCORRECT,
} from './types';

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
const isProduction = process.env.NODE_ENV === 'production';

const initialState = {
  authenticating: false,
  error: false,
  token: null,
  //email_account_exists used to show login/registration error when signin with already existing email/social account
  email_account_exists: false,
  password_incorrect: false,
};

const getters = {
  isAuthenticated: state => !!state.token,
  error (state) {
    return state.error
  },
  email_account_exists (state) {
    return state.email_account_exists
  },
  password_incorrect (state) {
    return state.password_incorrect
  }
};

const actions = {
  login({ commit }, { email, password }) {
    commit(LOGIN_BEGIN);
    commit(SET_PASSWORD_INCORRECT, false);
    return auth.login(email, password)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => commit(LOGIN_SUCCESS))
      .catch(() => {
        commit(LOGIN_FAILURE);
        commit(SET_PASSWORD_INCORRECT, true);
      });
  },
  logout({ commit }) {
    //we must not delete main token as it will be used for other things / by others
    if (!store.getters['accounts/user'].first_name && store.getters['auth/isAuthenticated']) {
      store.dispatch('accounts/get_normal_user', {
        user_id: store.getters['accounts/user_id'] 
      })
      .then(() => {
        if (!store.getters['accounts/user'].email=='roy@malmberg.io') {
          return auth.logout()
          .then(() => commit(LOGOUT))
          .finally(() => commit(REMOVE_TOKEN));
        } else {
          commit(LOGOUT)
          commit(REMOVE_TOKEN)
        }
      })
    } else {
      if (!store.getters['accounts/user'].email=='roy@malmberg.io') {
        return auth.logout()
        .then(() => commit(LOGOUT))
        .finally(() => commit(REMOVE_TOKEN));
      } else {
        commit(LOGOUT)
        commit(REMOVE_TOKEN)
      }
    }
  },
  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
/*
    if (isProduction && token) {
      commit(REMOVE_TOKEN);
    }

    if (!isProduction && token) {
      commit(SET_TOKEN, token);
    }
*/
    if (token) {
      commit(SET_TOKEN, token);
    }
  },
  post_login_linkedin({ commit }, payload) {
    commit(LOGIN_BEGIN);
    return auth.post_login_linkedin(payload)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => {
        commit(SET_EMAIL_ACCOUNT_EXISTS, false)
        commit(LOGIN_SUCCESS)
      })
      .catch(({response}) => {
        if (response.status==500) {
          commit(SET_EMAIL_ACCOUNT_EXISTS, true)
        }
        commit(LOGIN_FAILURE)
      });
  },
  post_login_facebook({ commit }, payload) {
    commit(LOGIN_BEGIN);
    return auth.post_login_facebook(payload)
      .then(({ data }) => {
        commit(SET_TOKEN, data.key)
      })
      .then(() => {
        commit(SET_EMAIL_ACCOUNT_EXISTS, false)
        commit(LOGIN_SUCCESS)
      })
      .catch(({response}) => {
        if (response.status==500) {
          commit(SET_EMAIL_ACCOUNT_EXISTS, true)
        }
        commit(LOGIN_FAILURE)
      });
  },
  post_create_free_account({ commit }, { email, first_name, last_name }) {
    commit(LOGIN_BEGIN);
    return auth.post_create_free_account(email, first_name, last_name)
      .then(({ data }) => commit(SET_TOKEN, data.key))
      .then(() => commit(LOGIN_SUCCESS))
      .catch(() => commit(LOGIN_FAILURE));
  },
};

const mutations = {
  [LOGIN_BEGIN](state) {
    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_FAILURE](state) {
    state.authenticating = false;
    state.error = true;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
    //also reset state for accounts.js
    store.commit('accounts/RESET_STATE_ON_LOGOUT')
  },
  [SET_TOKEN](state, token) {
    //if (!isProduction) localStorage.setItem(TOKEN_STORAGE_KEY, token);
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    session.defaults.headers.Authorization = `Token ${token}`;
    state.token = token;
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete session.defaults.headers.Authorization;
    state.token = null;
  },
  [SET_EMAIL_ACCOUNT_EXISTS](state, email_account_exists) {
    state.email_account_exists = email_account_exists
  },
  [SET_PASSWORD_INCORRECT](state, password_incorrect) {
    state.password_incorrect = password_incorrect
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};