import session from './session';

export default {
  get_customer_companies(params) {
    return session.get('/api/customer/companies/', { params: params });
  },
  get_customer_company(customer_company_id) {
    return session.get(`/api/customer/companies/${customer_company_id}/`);
  },
  delete_customer_company(customer_company_id) {
    return session.delete(`/api/customer/companies/${customer_company_id}/`);
  },
  post_customer_company(data) {
    return session.post('/api/customer/companies/', data);
  },
  patch_customer_company(customer_company_id, data) {
    return session.patch(`/api/customer/companies/${customer_company_id}/`, data);
  },
  get_users_and_licenses() {
    return session.get('/api/users_and_licenses/');
  },
  post_assign_license(form) {
    return session.post('/api/assign_license/', { form });
  },
  post_unassign_license(license_id) {
    return session.post('/api/unassign_license/', { license_id });
  },
  post_remove_license(form) {
    return session.post('/api/remove_license/', { form });
  },
  post_reactivate_license(form) {
    return session.post('/api/reactivate_license/', { form });
  },
  post_cancel_trial() {
    return session.post('/api/cancel_trial/');
  },
  get_stripe_public_key() {
    return session.get('/api/get_stripe_public_key/');
  },
  get_stripe_cards() {
    return session.get('/api/get_stripe_cards/');
  },
  post_add_stripe_card(stripeToken) {
    return session.post('/api/add_stripe_card/', { stripeToken });
  },
  get_purchase() {
    return session.get('/api/get_purchase/');
  },
  post_purchase(data) {
    return session.post('/api/post_purchase/', data);
  },
  get_nested_products(params) {
    return session.get('/api/nested/products/', { params: params });
  },
  get_nested_plans(params) {
    return session.get('/api/nested/plans/', { params: params });
  },
  patch_portal(portal_id, data) {
    return session.patch(`/api/portals/${portal_id}/`, data);
  },
  get_if_new_account_discount() {
    return session.get('/api/get_if_new_account_discount/');
  },
  get_trial_upgrade(params) {
    return session.get('/api/trial_upgrade/', { params: params });
  },
  post_trial_upgrade(data) {
    return session.post('/api/trial_upgrade/', data);
  },
  get_eversign_url(params) {
    return session.get('/api/get_eversign_url/', { params: params });
  },
};