// global
export const SAVE_ERROR = "SAVE_ERROR"
export const LOADING_START = "LOADING_START"
export const LOADING_END = "LOADING_END"
// related to modules
export const SAVE_MAILBOXES = "SAVE_MAILBOXES"
export const SAVE_MAILBOX = "SAVE_MAILBOX"
export const SAVE_MESSAGES = "SAVE_MESSAGES"
export const SAVE_MESSAGE = "SAVE_MESSAGE"
export const SAVE_TOTAL_MESSAGES = "SAVE_TOTAL_MESSAGES"

export const UPDATE_IN_REPLY_TO_CAMPAIGN = "UPDATE_IN_REPLY_TO_CAMPAIGN"
export const SET_SELECTED_MESSAGE = "SET_SELECTED_MESSAGE"

export const SAVE_TEST_IMAP_CONNECTION = "SAVE_TEST_IMAP_CONNECTION"
