import training from '@/api/training';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_LOADED_TRAINING,
        TOGGLE_COMPLETED_TRAINING_MODULE,
        SAVE_PPM_FORM,
       } from './types'

const state = {
  error: {},
  loading: false,
  loaded_training: {},
  ppm_form: '',
}
const getters = {
  error (state) {
    return state.error;
  },
  loaded_training (state) {
    return state.loaded_training;
  },
  ppm_form (state) {
    return state.ppm_form;
  }
}
const actions = {
  post_toggle_completed_training_module({ commit }, payload) {
    return training
      .post_toggle_completed_training_module(payload)
      .then(({data}) => {
        commit(TOGGLE_COMPLETED_TRAINING_MODULE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_loaded_training({ commit }, { params }) {
    return training
      .get_loaded_training(params)
      .then(({data}) => {
        commit(SAVE_LOADED_TRAINING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_ppm_form({ commit }, payload) {
    return training
      .post_ppm_form(payload)
      .then((res) => {
        commit(SAVE_PPM_FORM, '');
        let blob = new Blob([res.data], { type: res.headers['content-type'] });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = res.headers['content-disposition'].split('filename="')[1].replace('"','')
        link.click()
      })
      .catch((res) => {
        commit(SAVE_PPM_FORM, 'max_downloads_reached');
        //commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_LOADED_TRAINING] (state, loaded_training) {
    state.loaded_training = loaded_training
    state.error = {}
  },
  [TOGGLE_COMPLETED_TRAINING_MODULE] (state, data) {
    let match_found = false
    for (let i=0; i<state.loaded_training.training_series.length; i++) {
      for (let x=0; x<state.loaded_training.training_series[i].training_weeks.length; x++) {
        for (let y=0; y<state.loaded_training.training_series[i].training_weeks[x].training_modules.length; y++) {
          if (state.loaded_training.training_series[i].training_weeks[x].training_modules[y].training_module.id==data.training_module_id) {
            state.loaded_training.training_series[i].training_weeks[x].training_modules[y].completed=data.completed
            match_found=true
            break
          }
        }
        if (match_found) {
          break
        }
      }
      if (match_found) {
        break
      }
    }
    state.error = {}
  },
  [SAVE_PPM_FORM] (state, ppm_form) {
    state.ppm_form = ppm_form
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}