import settings from '@/api/settings';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_LINKEDIN_USER_SETTING,
        SAVE_LINKEDIN_USER_SETTINGS,
        SAVE_HUBSPOT_USER_SETTING,
        SAVE_HUBSPOT_USER_SETTINGS,
        SAVE_ALL_USER_PORTAL_SETTINGS,
        SAVE_MARKETING_QUESTIONNAIRES,
        SAVE_MARKETING_QUESTIONNAIRE,
        SAVE_EMAIL_SETTINGS,
        SAVE_EMAIL_SETTING,
        SAVE_CALENDAR_SETTINGS,
        SAVE_CALENDAR_SETTING,
        SAVE_COPY_GENERATOR,
       } from './types'

const state = {
  error: {},
  loading: false,
  linkedin_user_setting: {},
  linkedin_user_settings: [],
  hubspot_user_setting: {},
  hubspot_user_settings: [],
  all_user_portal_settings: [],
  marketing_questionnaires: [],
  marketing_questionnaire: {},
  email_settings: [],
  email_setting: {},
  calendar_settings: [],
  calendar_setting: {},
  copy_generator: {},
}
const getters = {
  error (state) {
    return state.error;
  },
  linkedin_user_setting (state) {
    return state.linkedin_user_setting;
  },
  linkedin_user_settings (state) {
    return state.linkedin_user_settings;
  },
  hubspot_user_setting (state) {
    return state.hubspot_user_setting;
  },
  hubspot_user_settings (state) {
    return state.hubspot_user_settings;
  },
  all_user_portal_settings (state) {
    return state.all_user_portal_settings;
  },
  marketing_questionnaires (state) {
    return state.marketing_questionnaires;
  },
  marketing_questionnaire (state) {
    return state.marketing_questionnaire;
  },
  email_settings (state) {
    return state.email_settings;
  },
  email_setting (state) {
    return state.email_setting;
  },
  calendar_settings (state) {
    return state.calendar_settings;
  },
  calendar_setting (state) {
    return state.calendar_setting;
  },
  copy_generator (state) {
    return state.copy_generator;
  },
}
const actions = {
  get_linkedin_user_setting({ commit }, { linkedin_user_settings_id }) {
    return settings
      .get_linkedin_user_setting(linkedin_user_settings_id)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_linkedin_user_settings({ commit }, { params }) {
    return settings
      .get_linkedin_user_settings(params)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_USER_SETTINGS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_linkedin_user_setting({ commit }, payload) {
    return settings
      .post_linkedin_user_setting(payload)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_linkedin_user_setting({ commit }, { linkedin_user_settings_id, payload }) {
    return settings
      .patch_linkedin_user_setting(linkedin_user_settings_id, payload)
      .then(({data}) => {
        commit(SAVE_LINKEDIN_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_start_linkedin_verification({ commit }, payload) {
    return settings
      .post_start_linkedin_verification(payload)
      .then(({data}) => {
        //commit(SAVE_LINKEDIN_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_hubspot_user_setting({ commit }, { hubspot_user_settings_id }) {
    return settings
      .get_hubspot_user_setting(hubspot_user_settings_id)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_hubspot_user_settings({ commit }, { params }) {
    return settings
      .get_hubspot_user_settings(params)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_USER_SETTINGS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_hubspot_user_setting({ commit }, payload) {
    return settings
      .post_hubspot_user_setting(payload)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_hubspot_user_setting({ commit }, { hubspot_user_settings_id, payload }) {
    return settings
      .patch_hubspot_user_setting(hubspot_user_settings_id, payload)
      .then(({data}) => {
        commit(SAVE_HUBSPOT_USER_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_all_user_portal_settings({ commit }, { params }) {
    return settings
      .get_all_user_portal_settings(params)
      .then(({data}) => {
        commit(SAVE_ALL_USER_PORTAL_SETTINGS, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_marketing_questionnaires({ commit }, { params }) {
    return settings
      .get_marketing_questionnaires(params)
      .then(({data}) => {
        commit(SAVE_MARKETING_QUESTIONNAIRES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_marketing_questionnaire({ commit }, payload) {
    return settings
      .post_marketing_questionnaire(payload)
      .then(({data}) => {
        commit(SAVE_MARKETING_QUESTIONNAIRE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_marketing_questionnaire({ commit }, { marketing_questionnaire_id, payload }) {
    return settings
      .patch_marketing_questionnaire(marketing_questionnaire_id, payload)
      .then(({data}) => {
        commit(SAVE_MARKETING_QUESTIONNAIRE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_email_settings({ commit }, { params }) {
    return settings
      .get_email_settings(params)
      .then(({data}) => {
        commit(SAVE_EMAIL_SETTINGS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_email_setting({ commit }, payload) {
    return settings
      .post_email_setting(payload)
      .then(({data}) => {
        commit(SAVE_EMAIL_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_email_setting({ commit }, { email_settings_id, payload }) {
    return settings
      .patch_email_setting(email_settings_id, payload)
      .then(({data}) => {
        commit(SAVE_EMAIL_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_calendar_settings({ commit }, { params }) {
    return settings
      .get_calendar_settings(params)
      .then(({data}) => {
        commit(SAVE_CALENDAR_SETTINGS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_calendar_setting({ commit }, payload) {
    return settings
      .post_calendar_setting(payload)
      .then(({data}) => {
        commit(SAVE_CALENDAR_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_calendar_setting({ commit }, { calendar_settings_id, payload }) {
    return settings
      .patch_calendar_setting(calendar_settings_id, payload)
      .then(({data}) => {
        commit(SAVE_CALENDAR_SETTING, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_copy_generator({ commit }, { params }) {
    return settings
      .get_copy_generator(params)
      .then(({data}) => {
        commit(SAVE_COPY_GENERATOR, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_LINKEDIN_USER_SETTING] (state, linkedin_user_setting) {
    state.linkedin_user_setting = linkedin_user_setting
    state.error = {}
  },
  [SAVE_LINKEDIN_USER_SETTINGS] (state, linkedin_user_settings) {
    state.linkedin_user_settings = linkedin_user_settings
    state.error = {}
  },
  [SAVE_HUBSPOT_USER_SETTING] (state, hubspot_user_setting) {
    state.hubspot_user_setting = hubspot_user_setting
    state.error = {}
  },
  [SAVE_HUBSPOT_USER_SETTINGS] (state, hubspot_user_settings) {
    state.hubspot_user_settings = hubspot_user_settings
    state.error = {}
  },
  [SAVE_ALL_USER_PORTAL_SETTINGS] (state, all_user_portal_settings) {
    state.all_user_portal_settings = all_user_portal_settings
    state.error = {}
  },
  [SAVE_MARKETING_QUESTIONNAIRES] (state, marketing_questionnaires) {
    state.marketing_questionnaires = marketing_questionnaires
    state.error = {}
  },
  [SAVE_MARKETING_QUESTIONNAIRE] (state, marketing_questionnaire) {
    state.marketing_questionnaire = marketing_questionnaire
    state.error = {}
  },
  [SAVE_EMAIL_SETTINGS] (state, email_settings) {
    state.email_settings = email_settings
    state.error = {}
  },
  [SAVE_EMAIL_SETTING] (state, email_setting) {
    state.email_setting = email_setting
    state.error = {}
  },
  [SAVE_CALENDAR_SETTINGS] (state, calendar_settings) {
    state.calendar_settings = calendar_settings
    state.error = {}
  },
  [SAVE_CALENDAR_SETTING] (state, calendar_setting) {
    state.calendar_setting = calendar_setting
    state.error = {}
  },
  [SAVE_COPY_GENERATOR] (state, copy_generator) {
    state.copy_generator = copy_generator
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}