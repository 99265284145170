import session from './session';

export default {
  get_notifications(params) {
    return session.get('/api/notifications/', { params: params });
  },
  get_notification(notification_id) {
    return session.get(`/api/notifications/${notification_id}/`);
  },
  delete_notification(notification_id) {
    return session.delete(`/api/notifications/${notification_id}/`);
  },
  post_notification(data) {
    return session.post('/api/notifications/', data);
  },
  patch_notification(notification_id, data) {
    return session.patch(`/api/notifications/${notification_id}/`, data);
  },
  get_all_notifications_for_user_portal(params) {
    return session.get('/api/all_notifications_for_user_portal/', { params: params });
  },
};