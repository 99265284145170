import inbox from '@/api/inbox';

import { LOADING_START,
		 LOADING_END,
     SAVE_ERROR,
		 SAVE_MAILBOXES,
		 SAVE_MAILBOX,
		 SAVE_MESSAGES,
		 SAVE_MESSAGE,
     SAVE_TOTAL_MESSAGES,
     UPDATE_IN_REPLY_TO_CAMPAIGN,
     SET_SELECTED_MESSAGE,
     SAVE_TEST_IMAP_CONNECTION,
       } from './types'

const state = {
  error: {},
  loading: false,
  mailboxes: [],
  mailbox: null,
  messages: [],
  message: null,
  total_messages: null,
  selected_message: {subject: '', from_address: [''], in_reply_to_campaign: '', html: '', text: '', can_be_part_of_campaign: false},
  test_imap_connection: {},
}
const getters = {
  error (state) {
    return state.error;
  },
  mailboxes (state) {
    return state.mailboxes
  },
  mailbox (state) {
    return state.mailbox
  },
  messages (state) {
    return state.messages
  },
  message (state) {
    return state.message
  },
  total_messages (state) {
    return state.total_messages
  },
  selected_message (state) {
    return state.selected_message
  },
  test_imap_connection (state) {
    return state.test_imap_connection
  }
}
const actions = {
  get_mailboxes({ commit }, { params }) {
    return inbox
      .get_mailboxes(params)
      .then(({data}) => {
        commit(SAVE_MAILBOXES, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_mailbox({ commit }, { mailbox_id }) {
    return inbox
      .get_mailbox(mailbox_id)
      .then(({data}) => {
        commit(SAVE_MAILBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_mailbox({ commit }, payload) {
    return inbox
      .post_mailbox(payload)
      .then(({data}) => {
        commit(SAVE_MAILBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_mailbox({ commit }, { mailbox_id, payload }) {
    return inbox
      .patch_mailbox(mailbox_id, payload)
      .then(({data}) => {
        commit(SAVE_MAILBOX, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  delete_mailbox({ commit }, { mailbox_id }) {
    return inbox
      .delete_mailbox(mailbox_id)
      .then(({data}) => {

      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_messages({ commit }, { params }) {
    return inbox
      .get_messages(params)
      .then(({data}) => {
        commit(SAVE_MESSAGES, data.results);
		    commit(SAVE_TOTAL_MESSAGES, data.count);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_message({ commit }, { message_id }) {
    return inbox
      .get_message(message_id)
      .then(({data}) => {
        commit(SAVE_MESSAGE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_message({ commit }, { message_id, data }) {
    return inbox
      .patch_message(message_id, data)
      .then(({data}) => {
        commit(SAVE_MESSAGE, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_test_imap_connection({ commit }, { email, password, server, port }) {
    return inbox
      .post_test_imap_connection(email, password, server, port)
      .then(({data}) => {
        commit(SAVE_TEST_IMAP_CONNECTION, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_MAILBOXES] (state, mailboxes) {
    state.mailboxes = mailboxes
    state.error = {}
  },
  [SAVE_MAILBOX] (state, mailbox) {
    state.mailbox = mailbox
    state.error = {}
  },
  [SAVE_MESSAGES] (state, messages) {
    state.messages = messages
    state.error = {}
  },
  [SAVE_MESSAGE] (state, message) {
    state.message = message
    state.error = {}
  },
  [SAVE_TOTAL_MESSAGES] (state, count) {
    state.total_messages = count
    state.error = {}
  },
  [UPDATE_IN_REPLY_TO_CAMPAIGN] (state, campaign_url) {
    state.selected_message.in_reply_to_campaign = campaign_url
    state.error = {}
  },
  [SET_SELECTED_MESSAGE] (state, message) {
    state.selected_message = message
    state.error = {}
  },
  [SAVE_TEST_IMAP_CONNECTION] (state, test_imap_connection) {
    state.test_imap_connection = test_imap_connection
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}