import survey from '@/api/survey';

import {LOADING_START,
		    LOADING_END,
        SAVE_ERROR,
        SAVE_SIGNUP_SURVEY,
        SAVE_SIGNUP_SURVEYS,
        SAVE_INVESTMENT_FOCUS_SURVEY,
        SAVE_INVESTMENT_FOCUS_SURVEYS,
       } from './types'

const state = {
  error: {},
  loading: false,
  signup_survey: {},
  signup_surveys: [],
}
const getters = {
  error (state) {
    return state.error;
  },
  signup_survey (state) {
    return state.signup_survey;
  },
  signup_surveys (state) {
    return state.signup_surveys;
  },
}
const actions = {
  get_signup_survey({ commit }, { signup_survey_id }) {
    return survey
      .get_signup_survey(signup_survey_id)
      .then(({data}) => {
        commit(SAVE_SIGNUP_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_signup_surveys({ commit }, { params }) {
    return survey
      .get_signup_surveys(params)
      .then(({data}) => {
        commit(SAVE_SIGNUP_SURVEYS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_signup_survey({ commit }, payload) {
    return survey
      .post_signup_survey(payload)
      .then(({data}) => {
        commit(SAVE_SIGNUP_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_signup_survey({ commit }, { signup_survey_id, payload }) {
    return survey
      .patch_signup_survey(signup_survey_id, payload)
      .then(({data}) => {
        commit(SAVE_SIGNUP_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_focus_survey({ commit }, { investment_focus_survey_id }) {
    return survey
      .get_investment_focus_survey(investment_focus_survey_id)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_FOCUS_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  get_investment_focus_surveys({ commit }, { params }) {
    return survey
      .get_investment_focus_surveys(params)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_FOCUS_SURVEYS, data.results);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  post_investment_focus_survey({ commit }, payload) {
    return survey
      .post_investment_focus_survey(payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_FOCUS_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
  patch_investment_focus_survey({ commit }, { investment_focus_survey_id, payload }) {
    return survey
      .patch_investment_focus_survey(investment_focus_survey_id, payload)
      .then(({data}) => {
        commit(SAVE_INVESTMENT_FOCUS_SURVEY, data);
      })
      .catch(({response}) => {
        commit(SAVE_ERROR, response.data.errors)
      })
  },
}
const mutations = {
  [LOADING_START] (state) {
    state.loading = true
  },
  [LOADING_END] (state) {
    state.loading = false
  },
  [SAVE_ERROR] (state, error) {
    state.error = error
  },
  [SAVE_SIGNUP_SURVEY] (state, signup_survey) {
    state.signup_survey = signup_survey
    state.error = {}
  },
  [SAVE_SIGNUP_SURVEYS] (state, signup_surveys) {
    state.signup_surveys = signup_surveys
    state.error = {}
  },
  [SAVE_INVESTMENT_FOCUS_SURVEY] (state, investment_focus_survey) {
    state.investment_focus_survey = investment_focus_survey
    state.error = {}
  },
  [SAVE_INVESTMENT_FOCUS_SURVEYS] (state, investment_focus_surveys) {
    state.investment_focus_surveys = investment_focus_surveys
    state.error = {}
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}