export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN';
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export const PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE';
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const REGISTRATION_BEGIN = 'REGISTRATION_BEGIN';
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const PASSWORD_CHANGE_BEGIN = 'PASSWORD_CHANGE_BEGIN';
export const PASSWORD_CHANGE_CLEAR = 'PASSWORD_CHANGE_CLEAR';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const PASSWORD_CREATE_BEGIN = 'PASSWORD_CREATE_BEGIN';
export const PASSWORD_CREATE_CLEAR = 'PASSWORD_CREATE_CLEAR';
export const PASSWORD_CREATE_FAILURE = 'PASSWORD_CREATE_FAILURE';
export const PASSWORD_CREATE_SUCCESS = 'PASSWORD_CREATE_SUCCESS';

export const SET_EMAIL_ACCOUNT_EXISTS = 'SET_EMAIL_ACCOUNT_EXISTS';
export const SET_PASSWORD_INCORRECT = 'SET_PASSWORD_INCORRECT';

