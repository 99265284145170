import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger';

//auth
import auth from './modules/auth/auth';
import password from './modules/auth/password';
import signup from './modules/auth/signup';


//accounts
import accounts from './modules/accounts/accounts';
//campaign
import campaign from './modules/campaign/campaign';
//inbox
import inbox from './modules/inbox/inbox';
//outbox
import outbox from './modules/outbox/outbox';
//central db
import central_db from './modules/central_db/central_db';
//dashboard
import dashboard from './modules/dashboard/dashboard';
//affiliate
import affiliate from './modules/affiliate/affiliate';
//support
import support from './modules/support/support';
//billing
import billing from './modules/billing/billing';
//settings
import settings from './modules/settings/settings';
//survey
import survey from './modules/survey/survey';
//training
import training from './modules/training/training';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    auth,
    password,
    signup,
	  accounts,
    campaign,
	  inbox,
    outbox,
    central_db,
    dashboard,
    affiliate,
    support,
    billing,
    settings,
    survey,
    training,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});